import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useService } from '.';
import { FEATURE_FLAGS } from '../lib/constants';
import { exponentialBackoffRetry, retryIf } from '../lib/utils';
import { Lease, OnboardingUserDetails, HttpError, PlaidUser } from '../types';
import { useAuth } from './useAuth';

export const initialPage = 0;
export const initialTotalPages = Number.MAX_SAFE_INTEGER;
const initialDetails = { userId: '', mobileNumber: '' };
const initialLease = { id: '', userId: '', createdAt: '', updatedAt: '' };
const initialPlaidUser = {
  id: '',
  userId: '',
  accountId: '',
  accessToken: '',
  createdAt: '',
  updatedAt: '',
};

type OnboardingContextValues = {
  isDetailsLoading: boolean;
  isLeaseLoading: boolean;
  isPlaidUserLoading: boolean;
  details: OnboardingUserDetails;
  lease: Lease;
  plaidUser: PlaidUser;
  setDetails: (
    details: OnboardingUserDetails
  ) => Promise<OnboardingUserDetails>;
  setLease: (lease: Lease) => Promise<Lease>;
  setPlaidUser: (plaidUser: PlaidUser) => Promise<PlaidUser>;
};

const OnboardingContext = React.createContext<OnboardingContextValues>({
  details: initialDetails,
  lease: initialLease,
  plaidUser: initialPlaidUser,
  isDetailsLoading: false,
  isLeaseLoading: false,
  isPlaidUserLoading: false,
  setDetails: (details) => Promise.resolve(initialDetails),
  setLease: (lease) => Promise.resolve(initialLease),
  setPlaidUser: (plaidUser) => Promise.resolve(initialPlaidUser),
});

const detailsCacheKey = 'onboarding-user-details';
const leaseCacheKey = 'onboarding-lease';
const plaidCacheKey = 'onboarding-plaid';

function OnboardingProvider(props) {
  const { userId } = useAuth();
  const onboardingService = useService<'onboarding'>('onboarding');
  const queryClient = useQueryClient();

  const {
    data: details,
    isLoading: isDetailsLoading,
    refetch: fetchDetails,
  } = useQuery<{}, HttpError, OnboardingUserDetails>(
    [detailsCacheKey, { userId: userId }],
    () => onboardingService.getUserDetails(userId),
    {
      refetchOnWindowFocus: false,
      retry: retryIf,
      retryDelay: exponentialBackoffRetry,
      enabled: !!userId,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {
    data: lease,
    isLoading: isLeaseLoading,
    refetch: fetchLease,
  } = useQuery<{}, HttpError, Lease>(
    [leaseCacheKey, { userId: userId }],
    () => onboardingService.getLease(userId),
    {
      refetchOnWindowFocus: false,
      retry: retryIf,
      retryDelay: exponentialBackoffRetry,
      enabled: !!userId && FEATURE_FLAGS.FETCH_LEASE,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {
    data: plaidUser,
    isLoading: isPlaidUserLoading,
    refetch: fetchPlaidUser,
  } = useQuery<{}, HttpError, PlaidUser>(
    [plaidCacheKey, { userId: userId }],
    () => onboardingService.getPlaidUser(userId),
    {
      refetchOnWindowFocus: false,
      retry: retryIf,
      retryDelay: exponentialBackoffRetry,
      enabled: !!userId && FEATURE_FLAGS.FETCH_PLAID_USER,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const setDetails = async (details) => {
    queryClient.setQueryData([detailsCacheKey, { userId: userId }], details);
  };

  const setLease = async (lease) => {
    queryClient.setQueryData([leaseCacheKey, { userId: userId }], lease);
  };

  const setPlaidUser = async (plaidUser) => {
    queryClient.setQueryData([plaidCacheKey, { userId: userId }], plaidUser);
  };

  return (
    <OnboardingContext.Provider
      value={{
        details,
        lease,
        plaidUser,
        isDetailsLoading,
        isLeaseLoading,
        isPlaidUserLoading,
        setDetails,
        setLease,
        setPlaidUser,
      }}
      {...props}
    />
  );
}

const useOnboarding = () => React.useContext(OnboardingContext);

export { OnboardingProvider, useOnboarding };
