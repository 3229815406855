import styled from '@emotion/styled';
import { Spin, SpinProps } from 'antd';
import { SpinSize } from 'antd/es/spin';
import { Modify } from '../lib/types';

const X_LARGE = 'xlarge';

type FormPageSpinnerProps = Modify<
  SpinProps,
  {
    size: SpinSize | 'xlarge';
  } & {
    bgcolor?: string;
  }
>;

const customSizes = [X_LARGE];
const customSizeToDimensions = {
  [X_LARGE]: 40,
};

const FormPageSpinner: React.FC<FormPageSpinnerProps> = ({
  bgcolor,
  size,
  ...props
}) => {
  const sizeOverride = customSizes.includes(size)
    ? customSizeToDimensions[size] || undefined
    : undefined;

  const sizeTypeOverride = sizeOverride ? 'large' : (size as SpinSize);

  const spinProps: SpinProps = { ...props, size: sizeTypeOverride };

  return (
    <FormPageSpinnerWrapper>
      <StyledSpin fontSize={sizeOverride} bgcolor={bgcolor} {...spinProps} />
    </FormPageSpinnerWrapper>
  );
};

const FormPageSpinnerWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 100vh;
  width: 100%;
  background: rgba(235, 235, 235, 0.6);
`;

const StyledSpin = styled(Spin)<{ fontSize?: number; bgcolor?: string }>`
  &.ant-spin {
    ${(props) =>
      props.fontSize &&
      `.ant-spin-dot {
        font-size: ${props.fontSize}px !important;

        .ant-spin-dot-item {

          width: ${props.fontSize * 0.4375}px !important;
          height: ${props.fontSize * 0.4375}px !important;
        }
      }`}
    .ant-spin-dot-item {
      background-color: ${(props) => props.bgcolor || 'black'} !important;
    }
  }
`;

export default FormPageSpinner;
