import styled from '@emotion/styled';
import React, { createRef, MutableRefObject, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { MultiPageFormProvider, useMultiPageForm } from '../../hooks';
import {
  UserDetailsPage,
  UserEmailPage,
  RentalAddressPage,
  RentalPaymentPage,
  WelcomePage,
  KYCPage,
  LinkBankPage,
  MonthlyRentPage,
  AddToWaitlist,
} from './pages';
import 'react-loading-skeleton/dist/skeleton.css';
import AppPage from '../AppPage';
import { OnboardingStep } from './shared/types';
import { OnboardingProvider } from '../../hooks/useOnboarding';
import { FEATURE_FLAGS } from '../../lib/constants';

const onboardingPages = (): OnboardingStep[] => {
  if (FEATURE_FLAGS.WAITLIST_ENABLED) {
    return [
      OnboardingStep.WELCOME,
      OnboardingStep.USER_LEGAL_NAME,
      OnboardingStep.USER_EMAIL,
      OnboardingStep.ADDRESS,
      OnboardingStep.WAITLIST,
    ];
  } else {
    return [
      OnboardingStep.WELCOME,
      OnboardingStep.USER_LEGAL_NAME,
      OnboardingStep.USER_EMAIL,
      OnboardingStep.ADDRESS,
      OnboardingStep.LINK_BANK_INFO,
      OnboardingStep.KYC,
      OnboardingStep.RENTAL_PAYMENT,
      OnboardingStep.LINK_RENT_PORTAL,
      OnboardingStep.MONTHLY_RENT,
      OnboardingStep.MONTHLY_RENT_DUE_AT,
      OnboardingStep.LEASE_DETAILS,
      OnboardingStep.LANDLORD_DETAILS,
      OnboardingStep.UPLOAD_LEASE,
      OnboardingStep.FORM_CONFIRMATION,
      OnboardingStep.ENROLLMENT_CONFIRMATION,
      OnboardingStep.FINISH_ONBOARDING_SCREEN,
    ];
  }
};
const parsePageParams = (pages: OnboardingStep[], search: string): number => {
  const queryParams = new URLSearchParams(search);
  const pageName = queryParams.get('page');
  return pageName
    ? pages.findIndex((p) => p.toLowerCase() === pageName.toLowerCase())
    : 0;
};

export const pages = onboardingPages();

export const pagesToIndex: Record<string, number> = pages.reduce(
  (accumulator, page, index) => {
    accumulator[`${page}`] = index;
    return accumulator;
  },
  {} as Record<string, number>
);

const OnboardingMultiPageForm: React.FC = () => {
  const {
    currentPage: page,
    setCurrentPage,
    setTotalPages,
  } = useMultiPageForm();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageNodeRefs: Record<string, MutableRefObject<any>> = pages.reduce(
    (obj, page) => {
      obj[page] = createRef();
      return obj;
    },
    {} as Record<string, MutableRefObject<any>>
  );
  const pageIndex = parsePageParams(pages, search);

  // Set total and current pages on load
  useEffect(() => {
    setTotalPages(pages.length);
  }, [setTotalPages]);

  useEffect(() => {
    if (page === undefined) {
      setCurrentPage(pageIndex);
    }
  }, [pageIndex, page, setCurrentPage]);

  useEffect(() => {
    const queryParam = page > 0 ? `?page=${pages[page].toLowerCase()}` : '';
    let timeout = setTimeout(
      () => history.push(`${pathname.toString()}${queryParam}`),
      1000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [page, history, pathname]);

  return (
    <AppPage>
      <OnboardingWrapper>
        <SwitchTransition mode={'in-out'}>
          <CSSTransition
            key={pages[page] || ''}
            nodeRef={pageNodeRefs[pages[page]]}
            classNames="fade"
            timeout={{
              appear: 0,
              enter: 250,
              exit: 250,
            }}
          >
            {(() => {
              switch (pages[page]) {
                case OnboardingStep.WELCOME:
                  return (
                    <WelcomePage
                      pageIndex={pagesToIndex[OnboardingStep.WELCOME]}
                      ref={pageNodeRefs[OnboardingStep.WELCOME]}
                    />
                  );
                case OnboardingStep.USER_LEGAL_NAME:
                  return (
                    <UserDetailsPage
                      pageIndex={pagesToIndex[OnboardingStep.USER_LEGAL_NAME]}
                      ref={pageNodeRefs[OnboardingStep.USER_LEGAL_NAME]}
                    />
                  );
                case OnboardingStep.USER_EMAIL:
                  return (
                    <UserEmailPage
                      pageIndex={pagesToIndex[OnboardingStep.USER_EMAIL]}
                      ref={pageNodeRefs[OnboardingStep.USER_EMAIL]}
                    />
                  );
                case OnboardingStep.ADDRESS:
                  return (
                    <RentalAddressPage
                      pageIndex={pagesToIndex[OnboardingStep.ADDRESS]}
                      ref={pageNodeRefs[OnboardingStep.ADDRESS]}
                    />
                  );
                case OnboardingStep.MONTHLY_RENT:
                  return (
                    <MonthlyRentPage
                      ref={pageNodeRefs[OnboardingStep.MONTHLY_RENT]}
                    />
                  );
                case OnboardingStep.RENTAL_PAYMENT:
                  return (
                    <RentalPaymentPage
                      ref={pageNodeRefs[OnboardingStep.RENTAL_PAYMENT]}
                    />
                  );
                case OnboardingStep.KYC:
                  return <KYCPage ref={pageNodeRefs[OnboardingStep.KYC]} />;
                case OnboardingStep.LINK_BANK_INFO:
                  return (
                    <LinkBankPage
                      ref={pageNodeRefs[OnboardingStep.LINK_BANK_INFO]}
                    />
                  );
                case OnboardingStep.WAITLIST:
                  return (
                    <AddToWaitlist
                      ref={pageNodeRefs[OnboardingStep.WAITLIST]}
                    />
                  );
                default:
                  return <></>;
              }
            })()}
          </CSSTransition>
        </SwitchTransition>
      </OnboardingWrapper>
    </AppPage>
  );
};

const Onboarding: React.FC = () => {
  return (
    <MultiPageFormProvider>
      <OnboardingProvider>
        <OnboardingMultiPageForm />
      </OnboardingProvider>
    </MultiPageFormProvider>
  );
};

const OnboardingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .transition-group {
    width: 100%;
  }
`;

export default Onboarding;
