import moment from 'moment';

export const LS_KEY_PREFIX = 'slice';
export const LS_USER_ID_KEY = `${LS_KEY_PREFIX}_userId`;
export const LS_REDIRECT_URL_KEY = `${LS_KEY_PREFIX}_redirectUrl`;

interface LocalStorage<T> {
  data: T;
  expiresAt: number;
}

const timestampMilliseconds = (milliseconds: number) =>
  new Date(Date.now() + milliseconds).getTime(); // 30 days

const hasExpired = (expiresAtMilliseconds: number) => {
  return moment(expiresAtMilliseconds) < moment();
};

const parse = <T>(cached: string): LocalStorage<T> | null => {
  try {
    const ls: LocalStorage<T> = JSON.parse(cached);
    return ls;
  } catch (err) {
    // console.error('Error occured parsing json ', err);
    return null;
  }
};

export const setLocalStorage = <T>(
  key: string,
  data: T,
  expiresAtMilliseconds = 1000 * 60 * 60 * 24 // one day
) => {
  // one year milli
  const expiresAt = timestampMilliseconds(expiresAtMilliseconds);
  localStorage.setItem(key, JSON.stringify({ data, expiresAt }));
};

export const getLocalStorage = <T>(key: string): T | null => {
  const cached = localStorage.getItem(key);
  const ls = cached ? parse(cached) : null;
  // Early return if nothing in cache
  if (!ls) return null;

  if (hasExpired(ls.expiresAt)) {
    removeLocalStorage(key);
    return null;
  } else {
    return ls.data as T;
  }
};

export const checkForLocalStorageExpiredKeys = () => {
  const keys = Object.keys(localStorage);

  keys.map((key) => getLocalStorage(key));
};

export const removeLocalStorage = (key: string) => localStorage.removeItem(key);
