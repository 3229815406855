import { useTheme } from '@emotion/react';
import { LayoutTheme } from '@slicepayments/web-components';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '.';

export type LayoutProps = {
  titleFontSize: string;
  descriptionFontSize: string;
  textAlign: 'center' | 'left';
  pageTheme: LayoutTheme;
};

export type LayoutPropsByDevice = {
  desktop: LayoutProps;
  mobile: LayoutProps;
};

export type LayoutTextAlign = 'center' | 'left';

export type UseLayout = {
  titleFontSize: string;
  descriptionFontSize: string;
  textAlign: LayoutTextAlign;
  pageTheme: LayoutTheme;
};

export type LayoutKind = 'display' | 'form' | 'formWithGraphic';

const desktop: LayoutProps = {
  titleFontSize: '3.0rem',
  descriptionFontSize: '1.125rem',
  textAlign: 'center',
  pageTheme: {},
};

const mobile: LayoutProps = {
  titleFontSize: '1.5rem',
  descriptionFontSize: '1.0rem',
  textAlign: 'left',
  pageTheme: {},
};

export const pageLayoutPropsByKind = {
  display: {
    desktop: {
      ...desktop,
      titleFontSize: '5.0rem',
    },
    mobile: {
      ...mobile,
      titleFontSize: '2.5rem',
    },
  },
  form: {
    desktop: {
      ...desktop,
      titleFontSize: '3.0rem',
    },
    mobile: {
      ...mobile,
      titleFontSize: '1.5rem',
      textAlign: 'center' as LayoutTextAlign,
    },
  },
  formWithGraphic: {
    desktop: {
      ...desktop,
      titleFontSize: '3.0rem',
    },
    mobile: {
      ...mobile,
      titleFontSize: '2.0rem',
    },
  },
};

export const defaultPageLayoutProps = { desktop, mobile };

const useLayout = (
  props: LayoutPropsByDevice = defaultPageLayoutProps
): UseLayout => {
  const { mobile, desktop } = props;
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const deviceProps = minWidthMedium ? desktop : mobile;

  const [layoutProps, setLayoutProps] = useState<LayoutProps>(deviceProps);

  useEffect(() => {
    setLayoutProps(deviceProps);
  }, [minWidthMedium, deviceProps]);

  return layoutProps;
};

export default useLayout;
