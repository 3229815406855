import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMultiPageForm, useWaitlist } from '../../../hooks';

const AddToWaitlist = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { addToWaitlist } = useWaitlist();
  const { setTransitioning } = useMultiPageForm();
  const history = useHistory();

  useEffect(() => {
    setTransitioning(true);
    addToWaitlist();
    history.push('/waitlist');
  }, [addToWaitlist, setTransitioning, history]);

  return <div ref={ref}></div>;
});

export default AddToWaitlist;
