import type { apiClient } from '../../hooks';
import { User } from '../../types';

export type UserService = {
  byUserId: (userId: string) => Promise<User>;
};

export const userServiceFactory = (apiClient: apiClient): UserService => ({
  byUserId: (userId: string): Promise<User> =>
    apiClient<User>(`/user/by-id?user_id=${userId}`, { method: 'GET' }),
});
