import React from 'react';
import {
  Footer,
  getTheme,
  Header,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { useLayout, useMultiPageForm } from '../../../hooks';
import { FormPageWrapper } from '../shared/components';
import { useHistory } from 'react-router';
import { pageLayoutPropsByKind } from '../../../hooks/useLayout';
import { onboardingPageAnimations } from '../shared/utils';
import SliceShield from '../assets/slice-shield.svg';
import { OnboardingPageProps } from '../shared/types';

const pageTheme = {
  bgColor: '#98C5F6',
  image: SliceShield,
};

const { animationName, duration, delayStep } = onboardingPageAnimations;

const WelcomePage = React.forwardRef<HTMLDivElement, OnboardingPageProps>(
  ({ pageIndex }, ref) => {
    const { titleFontSize, descriptionFontSize, textAlign } = useLayout(
      pageLayoutPropsByKind.formWithGraphic
    );
    const { currentPage, isTransitioning, setNextPage } = useMultiPageForm();

    const header = (
      <Header
        titleRow1={'Help us verify'}
        titleRow2={'your identity'}
        description={
          'We’re required by law to collect certain information that helps us know it’s you when you log in to Slice. It’s all about keeping your account safe.'
        }
        titleFontSize={titleFontSize}
        descriptionFontSize={descriptionFontSize}
        textAlign={textAlign}
        animate={true}
        animationName={animationName}
        animationDelayStepSeconds={delayStep}
        animationInitialDelaySeconds={delayStep}
        animationDurationSeconds={duration}
      />
    );

    const footer = (
      <Footer
        buttons={[
          {
            text: 'Continue',
            variant: 'primary',
            fill: 'filled',
            size: 'large',
            loading: currentPage > pageIndex || isTransitioning,
            onClick: () => setNextPage(),
          },
        ]}
      />
    );

    return (
      <FormPageWrapper ref={ref}>
        <ResponsiveLayout
          pageTheme={pageTheme}
          header={header}
          footer={footer}
        />
      </FormPageWrapper>
    );
  }
);

export default WelcomePage;
