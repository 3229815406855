import { snakeCaseToCamelCase } from '../lib/api';
import { Address } from '../types';
type GoogleAddressValidatorBody = {
  address: {
    regionCode?: string;
    locality: string;
    postalCode: string;
    addressLines: string[];
  };
  enableUspsCass: boolean;
};

type GoogleAddressValidatorResponse = {
  responseId: string;
  result: {
    verdict: {
      inputGranularity: string;
      validationGranularity: string;
      geocodeGranularity: string;
      addressComplete: boolean;
      hasInferredComponents: boolean;
    };
    address?: {
      formattedAddress?: string;
      postalAddress?: {
        regionCode: string;
        languageCode: string;
        postalCode: string;
        administrativeArea: string;
        locality: string;
        addressLines: string[];
      };
      addressComponents?: any[];
    };
    geocode?: {
      location?: {
        latitude: number;
        longitude: number;
      };
      placeId?: string;
    };
    metadata?: {
      business: boolean;
      poBox: boolean;
    };
    uspsData?: {
      standardizedAddress?: {
        firstAddressLine?: string;
        cityStateZipAddressLine?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        zipCodeExtension?: string;
      };
    };
  };
};

type UseGoogleAddressValidator = {
  validateAddress: (
    address: Address
  ) => Promise<GoogleAddressValidatorResponse>;
};

const GOOGLE_ADDRESS_BASE_URL = 'https://addressvalidation.googleapis.com';
const GOOGLE_ADDRESS_VALIDATION_API = `${GOOGLE_ADDRESS_BASE_URL}/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`;

const useGoogleAddressValidator = (): UseGoogleAddressValidator => {
  const toGoogleAddress = (address: Address): GoogleAddressValidatorBody => {
    return {
      address: {
        regionCode: address.countryCode,
        locality: address.city,
        postalCode: address.postalCode,
        addressLines: [address.address1, address.address2].filter(
          (line) => line !== undefined || line !== null || line !== ''
        ) as string[],
      },
      enableUspsCass: true,
    };
  };

  const validateAddress = (
    address: Address
  ): Promise<GoogleAddressValidatorResponse> => {
    return fetch(GOOGLE_ADDRESS_VALIDATION_API, {
      method: 'POST',
      body: JSON.stringify(toGoogleAddress(address)),
    })
      .then((response) => response.json())
      .then(snakeCaseToCamelCase);
  };

  return {
    validateAddress,
  };
};

export default useGoogleAddressValidator;
