import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type UseFormPage<T> = {
  form: FormInstance<any>;
  initialValues?: T;
  showCancelModal: boolean;
  isFormValid: boolean;
  isLoading: boolean;
  setShowCancelModal: React.Dispatch<SetStateAction<boolean>>;
  setIsFormValid: React.Dispatch<SetStateAction<boolean>>;
  openConfirm: (onConfirm: () => void) => void;
  onChange: () => void;
  hasChanges: () => boolean;
};

interface UseFormPageProps<TData, TFormValue> {
  data: TData;
  toFormValues: (data: TData) => TFormValue;
}

const useFormPage = <TData, T>(
  props: UseFormPageProps<TData, T>
): UseFormPage<T> => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [initialValues, setInitialValues] = useState<T>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [form] = Form.useForm();

  const dataRef = useRef<TData | undefined>(undefined);

  const onChange = useCallback(() => {
    const isValid = hasRequiredFields(form) && !hasErrors(form);
    setIsFormValid(isValid);
  }, [form]);

  const hasChanges = () =>
    JSON.stringify(form.getFieldsValue()) !== JSON.stringify(initialValues);

  const openConfirm = (onConfirm) => {
    const isTouched = form.isFieldsTouched();
    const hasFormChanged = hasChanges();

    if (isTouched && hasFormChanged) {
      setShowCancelModal(true);
    } else {
      onConfirm();
    }
  };

  useEffect(() => {
    const fetchInitialValues = async () => {
      const formValues = props.toFormValues(props.data);
      setInitialValues(formValues);
      form.setFieldsValue(formValues);
      setLoading(false);
    };

    if (props.data && props.data !== dataRef.current) {
      dataRef.current = props.data;
      fetchInitialValues();
      onChange();
    }
  }, [props, form, onChange, setInitialValues]);

  return {
    form,
    initialValues,
    showCancelModal,
    isFormValid,
    isLoading,
    setShowCancelModal,
    setIsFormValid,
    openConfirm,
    onChange,
    hasChanges,
  };
};

export default useFormPage;

export const hasRequiredFields = (form: FormInstance): boolean => {
  const allFields = form.getFieldsValue();

  let isValid = true;

  Object.keys(allFields).forEach((k) => {
    const v = allFields[k];
    const formItem = form.getFieldInstance(k);

    const input = formItem?.input ? formItem.input : document.getElementById(k);

    if (input && input.getAttribute('aria-required')) {
      const hasValue = v !== undefined && v !== null && v !== '';

      if (!hasValue) {
        isValid = false;
      }
    }
  });

  return isValid;
};

export const hasErrors = (form: FormInstance): boolean => {
  const fieldsWithError = form
    .getFieldsError()
    .filter((item) => item.errors.length > 0);
  return fieldsWithError.length > 0;
};
