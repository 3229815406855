import { LayoutTheme } from '@slicepayments/web-components';
import { OnboardingStep } from './types';

export const onboardingFormPageTheme: LayoutTheme = {
  fontColor: 'black',
};

export const onboardingPages = [
  OnboardingStep.WELCOME,
  OnboardingStep.USER_LEGAL_NAME,
  OnboardingStep.ADDRESS,
  OnboardingStep.KYC,
  OnboardingStep.RENTAL_PAYMENT,
  OnboardingStep.LINK_RENT_PORTAL,
  OnboardingStep.LINK_BANK_INFO,
  OnboardingStep.MONTHLY_RENT,
  OnboardingStep.MONTHLY_RENT_DUE_AT,
  OnboardingStep.LEASE_DETAILS,
  OnboardingStep.LANDLORD_DETAILS,
  OnboardingStep.UPLOAD_LEASE,
  OnboardingStep.FORM_CONFIRMATION,
  OnboardingStep.ENROLLMENT_CONFIRMATION,
  OnboardingStep.FINISH_ONBOARDING_SCREEN,
];
