import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { CSSProperties, useEffect, useRef, useState } from 'react';

type SupportTooltipProps = {
  tooltipContent: string;
  tooltipContentStyle?: CSSProperties;
  buttonText?: string;
  className?: string;
};

const SupportTooltip: React.FC<SupportTooltipProps> = ({
  tooltipContent,
  buttonText = 'Need help?',
  tooltipContentStyle = {
    textAlign: 'center',
  },
  className,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>();
  const titleComponent = <StyledTitle>{tooltipContent}</StyledTitle>;

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SupportTooltipContainer className={className} ref={ref}>
      <Tooltip
        placement="bottom"
        title={titleComponent}
        open={isOpen}
        arrowPointAtCenter={true}
        overlayInnerStyle={tooltipContentStyle}
      >
        <SpanContainer onClick={() => setIsOpen(!isOpen)}>
          <span>{buttonText}</span>
        </SpanContainer>
      </Tooltip>
    </SupportTooltipContainer>
  );
};

export default SupportTooltip;

const SupportTooltipContainer = styled.div`
  padding: 1rem;
`;

const StyledTitle = styled.span`
  font-size: 0.875rem;
`;

const SpanContainer = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  color: #a581fe;
  text-align: center;

  span {
    margin: 0 auto;
    cursor: pointer;
  }
`;
