import { useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { useQuery } from 'react-query';
import { PlaidCreateUserRequest } from '../lib/services/onboarding';
import { retryIf, exponentialBackoffRetry } from '../lib/utils';
import { HttpError, PlaidUser } from '../types';
import { useAuth } from './useAuth';
import { useService } from './useServices';

type UsePlaidAuth = {
  plaidConfig?: PlaidLinkOptions;
  plaidUser?: PlaidUser;
  isPlaidUserLoading: boolean;
  isAccountLinked?: boolean;
  generatePlaidConfig: (mobileNumber: string) => Promise<void>;
  error: ErrorEvent | null;
  ready: boolean;
  exit: Function;
  open: Function;
};

const usePlaidAuth = (): UsePlaidAuth => {
  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>();
  const [plaidUser, setPlaidUser] = useState<PlaidUser>();
  const [isAccountLinked, setIsAccountLinked] = useState<boolean>();
  const { userId } = useAuth();

  const onboardingService = useService<'onboarding'>('onboarding');

  const { error, ready, exit, open } = usePlaidLink(
    plaidConfig || ({} as PlaidLinkOptions)
  );

  const { refetch, isLoading: isPlaidUserLoading } = useQuery<
    {},
    HttpError,
    PlaidUser
  >(
    ['get-plaid-user', { userId: userId }],
    () => onboardingService.getPlaidUser(userId),
    {
      refetchOnWindowFocus: false,
      retry: retryIf,
      retryDelay: exponentialBackoffRetry,
      onSuccess: setPlaidUser,
      enabled: !!userId,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const upsertPlaidUser = async (request: PlaidCreateUserRequest) => {
    const currentPlaidUser = plaidUser ? plaidUser : (await refetch()).data;

    if (!currentPlaidUser) {
      const newPlaidUser = await onboardingService.createPlaidUser(request);
      setPlaidUser(newPlaidUser);
    }
  };

  const onSuccess: PlaidLinkOnSuccess = async (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    try {
      await upsertPlaidUser({
        userId: userId,
        accessToken: public_token,
        accountId: metadata.accounts[0].id,
      });
    } catch (e: any) {
      console.log('[Error] creating new plaid user');
    }
    setIsAccountLinked(true);
  };

  const generatePlaidConfig = async (mobileNumber: string) => {
    const { token } = await onboardingService.createPlaidLinkToken({
      userId: userId,
      mobileNumber: mobileNumber,
    });
    const plaidConfig = { token, onSuccess };
    setPlaidConfig(plaidConfig);
  };

  return {
    plaidConfig,
    plaidUser,
    isAccountLinked,
    isPlaidUserLoading,
    generatePlaidConfig,
    error,
    ready,
    exit,
    open,
  };
};

export default usePlaidAuth;
