import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useService } from '.';
import { exponentialBackoffRetry, retryIf } from '../lib/utils';
import { HttpError, WaitlistUser } from '../types';
import { useAuth } from './useAuth';

export const initialPage = 0;
export const initialTotalPages = Number.MAX_SAFE_INTEGER;
const initialWaitlistUser = {
  id: '',
  userId: '',
  createdAt: '',
  updatedAt: '',
  approvedAt: '',
  deletedAt: '',
};

type WaitlistContextValues = {
  waitlistUser: WaitlistUser;
  addToWaitlist: () => WaitlistUser;
  refetch: () => Promise<WaitlistUser>;
  isWaitlisted: boolean | null;
  isWaitlistUserLoading: boolean;
};

const WaitlistContext = React.createContext<WaitlistContextValues>({
  waitlistUser: initialWaitlistUser,
  addToWaitlist: () => initialWaitlistUser,
  refetch: () => Promise.resolve(initialWaitlistUser),
  isWaitlisted: false,
  isWaitlistUserLoading: true,
});

function WaitlistProvider(props) {
  const { userId } = useAuth();
  const onboardingService = useService<'onboarding'>('onboarding');

  const {
    data: waitlistedUser,
    isLoading,
    refetch,
  } = useQuery<{}, HttpError, WaitlistUser>(
    ['waitlist-user-details', { userId: userId }],
    () => onboardingService.getWaitlistedUser(userId),
    {
      refetchOnWindowFocus: false,
      retry: retryIf,
      retryDelay: exponentialBackoffRetry,
      enabled: !!userId,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const isWaitlisted = isLoading ? null : !!waitlistedUser;

  const addToWaitlist = async () => {
    if (waitlistedUser) return waitlistedUser;

    const addedUser = await onboardingService.addToWaitlist({
      userId: userId,
    });
    refetch();
    return addedUser;
  };

  return (
    <WaitlistContext.Provider
      value={{
        waitlistUser: waitlistedUser,
        addToWaitlist,
        refetch,
        isWaitlisted: isWaitlisted,
        isWaitlistUserLoading: isLoading,
      }}
      {...props}
    />
  );
}

const useWaitlist = () => React.useContext(WaitlistContext);

export { WaitlistProvider, useWaitlist };
