import React from 'react';
import {
  DynamicAnimation,
  Footer,
  Header,
  NavBar,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { useAuth, useLayout } from '../../hooks';
import {
  ContentWrapper,
  FormPageWrapper,
} from '../Onboarding/shared/components';
import AppPage from '../AppPage';
import { pageLayoutPropsByKind } from '../../hooks/useLayout';
import styled from '@emotion/styled';
import AvocadoLime from './assets/avocado-lime.svg';
import AppStore from './assets/app-store.png';
import GooglePlay from './assets/google-play.png';
import { useHistory } from 'react-router';

const pageTheme = {
  bgColor: '#FFDD9B',
  image: AvocadoLime,
};

const animationDelaySeconds = 0.1;
const animationDurationSeconds = 1;
const animationName = 'fadeIn';

const WaitlistPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { userId } = useAuth();
  const history = useHistory();
  const { titleFontSize, textAlign } = useLayout(
    pageLayoutPropsByKind.formWithGraphic
  );

  const share = () => {
    const body =
      encodeURI(`Check out Slice, a new way to pay your rent online 🍉`) +
      encodeURIComponent(`https://www.paywithslice.app`);
    const url = `sms:?&body=${body}`;
    window?.open(url, '_blank')?.focus();
  };

  const nav = <NavBar handleClick={() => history.goBack()} kind="navigation" />;

  const header = (
    <Header
      titleRow1={'Welcome Aboard! '}
      titleFontSize={titleFontSize}
      textAlign={textAlign}
      animate={true}
      animationName={animationName}
      animationDurationSeconds={animationDurationSeconds}
      animationDelayStepSeconds={animationDelaySeconds}
    />
  );

  const footer = (
    <Footer
      buttons={[
        {
          text: 'Share',
          variant: 'primary',
          disabled: false,
          onClick: share,
        },
      ]}
    />
  );

  return (
    <AppPage>
      <FormPageWrapper ref={ref}>
        <ResponsiveLayout
          pageTheme={pageTheme}
          nav={nav}
          header={header}
          content={
            <ContentWrapper>
              <Content>
                <Text>
                  <DynamicAnimation
                    animationName={animationName}
                    animationDurationSeconds={animationDurationSeconds}
                    animationDelaySeconds={animationDelaySeconds}
                  >
                    We’re working hard to get Slice ready! While we wrap up the
                    finishing touches, we’re adding people gradually to make
                    sure nothing breaks. :)
                  </DynamicAnimation>
                </Text>
                <Text>
                  <DynamicAnimation
                    animationName={animationName}
                    animationDurationSeconds={animationDurationSeconds}
                    animationDelaySeconds={animationDelaySeconds * 1.5}
                  >
                    While you wait, please share Slice with your friends to move
                    up the waitlist faster.
                  </DynamicAnimation>
                </Text>
              </Content>
              <AppLinks>
                <Link target="_blank" href={`https://www.apple.com/app-store`}>
                  <DynamicAnimation
                    animationName={animationName}
                    animationDurationSeconds={animationDurationSeconds}
                    animationDelaySeconds={animationDelaySeconds * 2}
                  >
                    <DownloadImage src={AppStore} />
                  </DynamicAnimation>
                </Link>
                <Link
                  target="_blank"
                  href={`https://play.google.com/store/apps`}
                >
                  <DynamicAnimation
                    animationName={animationName}
                    animationDurationSeconds={animationDurationSeconds}
                    animationDelaySeconds={animationDelaySeconds * 2}
                  >
                    <DownloadImage src={GooglePlay} />
                  </DynamicAnimation>
                </Link>
              </AppLinks>
            </ContentWrapper>
          }
          footer={footer}
        />
      </FormPageWrapper>
    </AppPage>
  );
});

export default WaitlistPage;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Text = styled.div`
  font-size: 1rem;
  padding: 0.5rem 0px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1.125rem;
  }
`;

const AppLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 1rem;
  }
`;

const Link = styled.a`
  width: 125px;
  height: 36.36px;

  &:nth-of-type(2n) {
    margin-left: 1rem;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 150px;
    height: 43.63px;
  }
`;

const DownloadImage = styled.img`
  width: 100%;
  height: 100%;
`;
