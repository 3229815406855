import styled from '@emotion/styled';
import { Icon } from '@slicepayments/web-components';
import React from 'react';

export type MaskedInputFieldButtonProps = {
  maskContent: boolean;
  handleOnClick: () => void;
  iconWidth?: number;
};

const MaskedInputFieldButton: React.FC<MaskedInputFieldButtonProps> = ({
  maskContent,
  handleOnClick,
  iconWidth,
}) => {
  return (
    <MaskIconWrapper
      width={iconWidth || 28}
      className={'icon'}
      onClick={handleOnClick}
    >
      <Icon iconName={maskContent ? 'eyeClosed' : 'eyeOpen'} color={'black'} />
    </MaskIconWrapper>
  );
};

export default MaskedInputFieldButton;

const MaskIconWrapper = styled.div<{ width: number }>`
  width: ${(props) => `${props.width}px !important`};
  height: ${(props) => `${props.width}px !important`};

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.7);
  }
`;
