import React, { createRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  Footer,
  Header,
  Heading,
  HeadingElementTypes,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { Alert, Form, Input } from 'antd';
import { useMediaQuery, useMultiPageForm } from '../../../hooks';
import { onboardingFormPageTheme } from '../shared/constants';
import { FormPageWrapper } from '../shared/components';
import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import { hasErrors, hasRequiredFields, mockEndpoint } from '../shared/utils';
import appfolioLogoImg from '../assets/appfolio-logo.png';
import styled from '@emotion/styled';
import MaskedInputFieldButton from '../../../components/MaskInputFieldButton';
import {
  INPUT_WIDTH_DESKTOP,
  INPUT_WIDTH_MOBILE,
} from '../../../lib/constants';

// Types
export type PortalProps = {
  image: string;
  backgroundColor: string;
  value: PortalKind;
};

export type PortalLoginProps = {
  goBack: () => void;
  goNext: () => void;
} & PortalProps;

const enum PortalKind {
  APPFOLIO = 'APPFOLIO',
}

// Constants
const pageTitleRow1 = 'What portal do you use?';
const pageDescription = 'The website or URL where you pay your monthly rent';

const portals: PortalProps[] = [
  {
    image: appfolioLogoImg,
    backgroundColor: '#0090BF',
    value: PortalKind.APPFOLIO,
  },
];

// Page
const AppFolioLogin: React.FC<PortalLoginProps> = ({
  image,
  backgroundColor,
  goBack,
  goNext,
}) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [maskPassword, setMaskPassword] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [form] = Form.useForm();

  const isValid = () => hasRequiredFields(form) && !hasErrors(form);

  const onChange = () => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setIsFormValid(isValid());
  };

  const openConfirm = () => {
    const isTouched = form.isFieldsTouched();

    if (isTouched) {
      setshowCancelModal(true);
    } else {
      goBack();
    }
  };

  const submitForm = async () => {
    try {
      await mockEndpoint(true);
      goNext();
    } catch {
      form.setFields([
        {
          name: 'username',
          errors: [''],
        },
        {
          name: 'password',
          errors: [''],
        },
      ]);
      setErrorMessage('Username/password not found');
    }
  };

  const maskInputFieldButton = (
    <MaskedInputFieldButton
      maskContent={maskPassword}
      handleOnClick={() => setMaskPassword(!maskPassword)}
    />
  );

  return (
    <FormPageWrapper ref={createRef()}>
      <ConfirmCancelModal
        showModal={showCancelModal}
        onCancel={() => setshowCancelModal(false)}
        onOk={() => {
          setshowCancelModal(false);
          goBack();
        }}
      />
      <ResponsiveLayout
        pageTheme={{ bgColor: backgroundColor }}
        header={<PortalLogo src={image} />}
        content={
          <PortalLoginFormWrapper>
            <PortalFormHeader>
              {minWidthMedium && (
                <Heading
                  className={'portal-login-sub-header'}
                  type={HeadingElementTypes.h2}
                >
                  Portal Login
                </Heading>
              )}
              {errorMessage && (
                <StyledAlert
                  message={errorMessage}
                  type="error"
                  showIcon
                  closable
                />
              )}
            </PortalFormHeader>
            <Form form={form} onChange={onChange} layout="vertical">
              <Form.Item
                className="slice"
                name="username"
                label="Username"
                hasFeedback
                rules={[{ required: true }]}
              >
                <Input placeholder={'Enter username'} />
              </Form.Item>
              <StyledInputFieldWithIconButton
                className="slice"
                name="password"
                label="Password"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={'Enter password'}
                  suffix={maskInputFieldButton}
                  type={maskPassword ? 'password' : 'text'}
                />
              </StyledInputFieldWithIconButton>
            </Form>
          </PortalLoginFormWrapper>
        }
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                disabled: false,
                onClick: openConfirm,
              },
              {
                text: 'Sign In',
                fill: 'filled',
                variant: 'black',
                disabled: !isFormValid,
                onClick: submitForm,
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
};

const RentalPortalPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const { currentPage, totalPages, setPreviousPage, setNextPage } =
    useMultiPageForm();
  const [portalKind, setPortalKind] = useState<PortalKind | null>();
  const [showPortalLogin, setShowPortalLogin] = useState<boolean>(false);

  const removePortalKind = () => {
    setShowPortalLogin(false);
    setPortalKind(null);
  };

  const PortalLoginByKind: React.FC<{ portalKind: PortalKind | null }> = ({
    portalKind,
  }) => {
    if (portalKind === PortalKind.APPFOLIO) {
      const portal = portals.find((p) => p.value === PortalKind.APPFOLIO)!!;
      return (
        <AppFolioLogin
          {...portal}
          goBack={removePortalKind}
          goNext={setNextPage}
        />
      );
    } else {
      return <></>;
    }
  };

  const Content = (
    <>
      {portals.map((p) => (
        <PortalLogoContainer
          key={p.value}
          bgColor={p.backgroundColor}
          className={portalKind === p.value ? 'selected' : ''}
          onClick={() => setPortalKind(p.value)}
        >
          <PortalLogo src={p.image} />
        </PortalLogoContainer>
      ))}
    </>
  );

  return portalKind && showPortalLogin ? (
    <PortalLoginByKind portalKind={portalKind} />
  ) : (
    <FormPageWrapper ref={ref}>
      <ResponsiveLayout
        pageTheme={
          minWidthMedium ? onboardingFormPageTheme : onboardingFormPageTheme
        }
        header={
          <Header titleRow1={pageTitleRow1} description={pageDescription} />
        }
        content={Content}
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                textDecoration: 'underline',
                disabled: currentPage === 0,
                onClick: setPreviousPage,
              },
              {
                text: 'Next',
                fill: 'filled',
                variant: 'primary',
                disabled: !portalKind,
                onClick: () => setShowPortalLogin(true),
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
});

export default RentalPortalPage;

// Styled
const PortalLogoContainer = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  border: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 4px;
  opacity: 0.8;
  transition: all 0.25s ease-in;
  background: ${(props) => props.bgColor || 'inherit'} !important;
  width: ${INPUT_WIDTH_MOBILE}px;

  &:hover {
    opacity: 1;
  }

  &.selected {
    border-color: ${({ theme }) => theme.colorMap.gray.gray2};
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: ${INPUT_WIDTH_DESKTOP}px;
  }
`;

const PortalLogo = styled.img`
  object-fit: cover;
`;

const PortalLoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .slice .ant-form-item-label label {
    color: white;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    .slice .ant-form-item-label label {
      color: black;
    }
  }
`;

const StyledInputFieldWithIconButton = styled(Form.Item)`
  .ant-col.ant-form-item-control input {
    border: none !important;
  }
`;

const PortalFormHeader = styled.div`
  position: relative;
  min-height: 100px;
  margin-bottom: 0.25rem;
`;

const StyledAlert = styled(Alert)`
  position: absolute;
  bottom: 0;
`;
