import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { TimeoutProps } from 'react-transition-group/Transition';
import PageLoader from './PageLoader';

export interface ConditionalRouteProps extends RouteProps {
  redirectIf: Promise<boolean | null>;
  redirectPath: string;
}

const ConditionalRoute = ({
  redirectIf,
  redirectPath,
  path,
  component,
  children,
  ...rest
}: ConditionalRouteProps) => {
  const [shouldRedirect, setShouldRedirect] = useState<boolean | null>(null);

  useEffect(() => {
    const waitToLoad = (callback) => {
      return setTimeout(callback, 1000);
    };

    let timeout;
    redirectIf.then((bool) => {
      timeout = waitToLoad(() => setShouldRedirect(bool));
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [redirectIf]);

  if (shouldRedirect === null) {
    return <PageLoader />;
  } else if (shouldRedirect) {
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: path },
            }}
          />
        )}
      />
    );
  } else {
    return (
      <Route path={path} component={component} {...rest}>
        {children}
      </Route>
    );
  }
};

export default ConditionalRoute;

const SpinnerContainer = styled.div`
  height: 100%;
  height: 100vh;
  width: 100%;
`;
