import React, { SetStateAction } from 'react';

export const initialPage = 0;
export const initialTotalPages = Number.MAX_SAFE_INTEGER;

type PromiseFn = (promise: Promise<any>) => Promise<any>;

type MultiPageFormContextValues = {
  currentPage: number;
  totalPages: number;
  isTransitioning: boolean;
  setPreviousPage: () => void;
  setNextPage: () => void;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  setTotalPages: React.Dispatch<SetStateAction<number>>;
  setTransitioning: React.Dispatch<SetStateAction<boolean>>;
  onPageSubmit: PromiseFn;
};

const MultiPageFormContext = React.createContext<MultiPageFormContextValues>({
  currentPage: initialPage,
  totalPages: initialTotalPages,
  isTransitioning: false,
  setPreviousPage: () => {},
  setNextPage: () => {},
  setCurrentPage: () => {},
  setTotalPages: () => {},
  setTransitioning: () => {},
  onPageSubmit: (promise) => Promise.resolve({}),
});

function MultiPageFormProvider(props) {
  const [currentPage, setCurrentPage] = React.useState<number>();
  const [totalPages, setTotalPages] = React.useState<number>(initialTotalPages);
  const [isTransitioning, setTransitioning] = React.useState<boolean>(false);

  const setPreviousPage = () => {
    if (currentPage !== undefined) {
      setCurrentPage(Math.max(0, currentPage - 1));
    }
  };
  const setNextPage = () => {
    if (currentPage !== undefined) {
      setCurrentPage(Math.min(totalPages - 1, currentPage + 1));
    }
  };

  const onPageSubmit = async (promise: Promise<any>): Promise<any> => {
    setTransitioning(true);

    const response = await promise.catch((e) => {
      console.error(e);
      setTransitioning(false);
      return Promise.reject();
    });

    setTransitioning(false);

    return Promise.resolve(response);
  };

  return (
    <MultiPageFormContext.Provider
      value={{
        currentPage,
        totalPages,
        isTransitioning,
        setPreviousPage,
        setNextPage,
        setCurrentPage: (page: number) => {
          setCurrentPage(page);
        },
        setTotalPages,
        setTransitioning,
        onPageSubmit,
      }}
      {...props}
    />
  );
}

const useMultiPageForm = () => React.useContext(MultiPageFormContext);

export { MultiPageFormProvider, useMultiPageForm };
