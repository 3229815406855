export const termsOfServiceUrl = 'https://www.paywithslice.com/terms';
export const privacyPolicyUrl = 'https://www.paywithslice.com/privacy';

export const DEFAULT_QUERY_CACHE_STALE_TIME = 5 * 60 * 100; // 5 minutes of caching

export const LEFT_ARROW_KEY_CODE = 37;
export const RIGHT_ARROW_KEY_CODE = 39;
export const DOWN_ARROW_KEY_CODE = 40;
export const UP_ARROW_KEY_CODE = 38;
export const INPUT_WIDTH_MOBILE = 350;
export const INPUT_WIDTH_DESKTOP = 450;
export const ANT_INPUT_CONTAINER_HEIGHT = 60;
export const ANT_INPUT_HEIGHT = 50;

export const FEATURE_FLAGS = {
  WAITLIST_ENABLED: true,
  FETCH_LEASE: false,
  FETCH_PLAID_USER: false,
};
