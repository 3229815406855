import { Switch, Route } from 'react-router-dom';
import Onboarding from './pages/Onboarding';
import Waitlist from './pages/Waitlist';
import { Redirect } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';
import { ConditionalRoute } from './components';
import { useWaitlist } from './hooks';

const Router = () => {
  const { isWaitlisted } = useWaitlist();

  return (
    <HelmetProvider>
      <Switch>
        <Route exact path="/waitlist">
          <>
            <Waitlist />
            <Helmet>
              <title>Welcome to Slice</title>
              <meta
                name="description"
                content="We're excited to have you join our community. While you wait, please share Slice with your friends to move up the waitlist faster."
              />
              <link
                rel="canonical"
                href={`${process.env.REACT_APP_SLICE_WEB_URL}/waitlist`}
              />
            </Helmet>
          </>
        </Route>
        <ConditionalRoute
          redirectIf={Promise.resolve(false)}
          redirectPath={`/waitlist`}
          path="/join"
          component={Onboarding}
        >
          <>
            <Onboarding />
            <Helmet>
              <title>Rent now pay later</title>
              <meta
                name="description"
                content="Start splitting your rent payments today with Slice. Join now."
              />
              <link
                rel="canonical"
                href={`${process.env.REACT_APP_SLICE_WEB_URL}/join`}
              />
            </Helmet>
          </>
        </ConditionalRoute>
        <Redirect to="/join" />
      </Switch>
    </HelmetProvider>
  );
};

export default Router;
