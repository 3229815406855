import styled from '@emotion/styled';
import Form from 'antd/es/form/Form';

export const FormPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colorMap.neutral.black};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 2rem;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;
