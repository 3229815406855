import { css, Global } from '@emotion/react';
import { BrandTheme as SliceTheme } from '@slicepayments/web-components';
import {
  ANT_INPUT_HEIGHT,
  ANT_INPUT_CONTAINER_HEIGHT,
  INPUT_WIDTH_MOBILE,
  INPUT_WIDTH_DESKTOP,
} from './lib/constants';

type GlobalStylesProps = {
  theme: SliceTheme;
};

const getStyles = (theme) => css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffffff;
    overflow: hidden;
  }

  #root {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  @media ${theme.mediaQuery.medium} {
    #root {
      overflow-x: visible;
      overflow-y: visible;
    }
  }

  a,
  a:visited {
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  figure {
    margin: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
  // Antd specific
  .slice.ant-form-item {
    margin-bottom: 1.25rem;
  }

  .slice .ant-col.ant-form-item-label {
    padding: 0 0 6px;

    label {
      font-size: 1rem;
    }
  }

  .slice .ant-col.ant-form-item-control {
    box-sizing: border-box;
    width: ${INPUT_WIDTH_MOBILE}px;
    min-height: ${ANT_INPUT_CONTAINER_HEIGHT}px !important;
    background: #ffffff;
    border-radius: 4px;

    @media ${theme.mediaQuery.medium} {
      width: ${INPUT_WIDTH_DESKTOP}px;
    }

    div {
      height: 100%;
    }

    input {
      font-size: 1rem;
      height: ${ANT_INPUT_HEIGHT}px !important;
      border: 1px solid #e0e0e0;
    }

    .masked input {
      font-size: 1.05rem !important;
      letter-spacing: 0.125rem;
    }

    .ant-form-item-control-input-content {
      input:read-only {
        color: rgba(0, 0, 0, 1) !important;
      }

      // input number field
      .ant-input-number-affix-wrapper {
        width: 100%;

        input {
          border: 1px solid transparent;
        }
      }
    }

    .ant-select {
      width: ${INPUT_WIDTH_MOBILE}px;

      @media ${theme.mediaQuery.medium} {
        width: ${INPUT_WIDTH_DESKTOP}px;
      }

      .ant-select-selector {
        padding: 4px 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${ANT_INPUT_CONTAINER_HEIGHT}px !important;
      }
    }
  }
  // Antd specific
  .slice .ant-form-item-margin-offset {
    display: none;
  }

  .slice .ant-input-affix-wrapper-has-feedback {
    input {
      border: none !important;
    }
  }

  .slice .slick-dots {
    bottom: 100px !important;
    top: auto !important;

    button {
      height: 8px !important;
      width: 8px !important;
      border-radius: 8px !important;
      background: gray !important;
    }

    .slick-active button {
      background: black !important;
    }

    > * {
      color: black;
    }
  }

  .fade-appear,
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms linear 250ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 1;
    transition: opacity 250ms linear 250ms;
  }
`;

const GlobalStyles = ({ theme }: GlobalStylesProps) => (
  <Global styles={getStyles(theme)} />
);

export default GlobalStyles;
