import React from 'react';
import { FormProps, Rule } from 'antd/lib/form';
import { StyledForm } from '../shared/components';
import { Form, Input } from 'antd';

// Types
export interface UserEmailFormProps extends FormProps {
  form: any;
  onChange: any;
}

// Constants
const emailRules: Rule[] = [
  {
    required: true,
    type: 'email',
    message: 'The input is not valid email',
  },
];

// Component
const UserEmailForm: React.FC<UserEmailFormProps> = ({ form, onChange }) => {
  return (
    <StyledForm
      form={form}
      onChange={onChange}
      validateTrigger="onSubmit"
      layout="vertical"
    >
      <Form.Item
        className="slice"
        name="email"
        label="Email"
        rules={emailRules}
        hasFeedback
      >
        <Input placeholder="Enter email" />
      </Form.Item>
    </StyledForm>
  );
};

export default UserEmailForm;
