import styled from '@emotion/styled';
import {
  Button,
  ResponsiveLayout,
  defaultMaxWidth,
  Header,
  NavBar,
} from '@slicepayments/web-components';
import { Carousel } from 'antd';
import React from 'react';
import SliceDesktopBgImage from '../shared/assets/slice-desktop-hero-bg.png';
import SliceMobileBgImage from '../shared/assets/slice-mobile-hero-bg.png';
import SliceMoneyGraphic from '../shared/assets/slice-split-rent-graphic.svg';
import SliceGoGraphic from '../shared/assets/slice-go-vert-graphic.svg';
import SliceBuildCreditGraphic from '../shared/assets/slice-build-credit-graphic.svg';
import { useLayout } from '../../../hooks';
import { useHistory } from 'react-router';
import { LoginFlow } from '..';
import { pageLayoutPropsByKind } from '../../../hooks/useLayout';

export const homePadding: number = 24;
export const slide2Color = '#E4FE55';
export const slide3Color = '#F9DE4A';
export const slide4Color = '#98C5F6';

const Home: React.FC = () => {
  const { titleFontSize, descriptionFontSize, textAlign } = useLayout(
    pageLayoutPropsByKind.display
  );

  const history = useHistory();

  const headerSlide1 = (
    <Slide1HeaderContainer>
      <Header
        titleRow1={'Welcome to Slice'}
        description={
          'Get access to the tools you need to pay rent, boost your credit, and save money.'
        }
        titleFontSize={titleFontSize}
        descriptionFontSize={descriptionFontSize}
      />
    </Slide1HeaderContainer>
  );

  const headerSlide2 = (
    <Header
      titleRow1={'Split your rent into'}
      titleRow2={'two payments'}
      description={
        'Slice unlocks financial services for the needs of modern renters.'
      }
      titleFontSize={titleFontSize}
      descriptionFontSize={descriptionFontSize}
      textAlign={textAlign}
    />
  );

  const headerSlide3 = (
    <Header
      titleRow1={'Start for just $5'}
      description={
        'Rent check stress? Late fees? BS charges? We’ll take care of your rent finances so you can stop worrying and enjoy living.'
      }
      titleFontSize={titleFontSize}
      descriptionFontSize={descriptionFontSize}
      textAlign={textAlign}
    />
  );

  const headerSlide4 = (
    <Header
      titleRow1={'Build your credit'}
      description={
        'Get rewarded for renting. From day one we work to boost your credit, so you can enjoy a great financial future.'
      }
      titleFontSize={titleFontSize}
      descriptionFontSize={descriptionFontSize}
      textAlign={textAlign}
    />
  );

  // Shared components
  const nav = <NavBar kind={'blank'} handleClick={() => {}} />;
  const footer = <></>;

  const toLoginPage = (route: LoginFlow) => history.push(`/${route}`);

  return (
    <HomeContainer>
      <Carousel className="slice" effect={'scrollx'} dotPosition={'top'}>
        <Slide1>
          <MainLayout>
            <MainLayoutContent>{headerSlide1}</MainLayoutContent>
          </MainLayout>
        </Slide1>
        <Slide2>
          <ResponsiveLayout
            pageTheme={{
              bgColor: slide2Color,
              image: SliceMoneyGraphic,
            }}
            nav={nav}
            header={headerSlide2}
            footer={footer}
            content={
              <DisclaimerSection>
                <span>
                  Lines of credit are offered through Slice Payments, Inc. via
                  Blue Ridge Bank, N.A. Member
                  <DisclaimerLink
                    href={'https://www.fdic.gov/'}
                    target="_blank"
                  >
                    FDIC.
                  </DisclaimerLink>
                </span>
              </DisclaimerSection>
            }
          />
        </Slide2>
        <Slide3>
          <ResponsiveLayout
            pageTheme={{
              bgColor: slide3Color,
              image: SliceGoGraphic,
            }}
            nav={nav}
            header={headerSlide3}
            footer={footer}
            content={
              <DisclaimerSection>
                <span>
                  Limitations and other fees may apply. View our Customer
                  Agreement and Privacy Policy to learn more.
                </span>
              </DisclaimerSection>
            }
          />
        </Slide3>

        <Slide4>
          <ResponsiveLayout
            pageTheme={{
              bgColor: slide4Color,
              image: SliceBuildCreditGraphic,
            }}
            nav={nav}
            header={headerSlide4}
            footer={footer}
            content={
              <DisclaimerSection>
                <span>
                  We partner with
                  <DisclaimerLink
                    target="_blank"
                    href="https://www.experian.com/"
                  >
                    Experian
                  </DisclaimerLink>
                  ,
                  <DisclaimerLink
                    target="_blank"
                    href="https://www.transunion.com/"
                  >
                    TransUnion
                  </DisclaimerLink>{' '}
                  and
                  <DisclaimerLink
                    target="_blank"
                    href="https://www.equifax.com/"
                  >
                    Equifax
                  </DisclaimerLink>{' '}
                  to improve your credit history.
                </span>
              </DisclaimerSection>
            }
          />
        </Slide4>
      </Carousel>
      <FooterWrapper>
        <Button
          fill={'unfilled'}
          variant={'black'}
          border={'border'}
          disabled={false}
          size={'medium'}
          onClick={() => toLoginPage(LoginFlow.LOGIN)}
        >
          Log in
        </Button>
        <Button
          fill={'filled'}
          variant={'black'}
          disabled={false}
          size={'medium'}
          onClick={() => toLoginPage(LoginFlow.SIGNUP)}
        >
          Sign up
        </Button>
      </FooterWrapper>
    </HomeContainer>
  );
};

export default Home;

const HomeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MainLayout = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: center;
  }
`;

const MainLayoutContent = styled.div`
  width: 100%;
  height: auto;
`;

const Slide1HeaderContainer = styled.div`
  height: 100%;
`;

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Slide1 = styled(Slide)`
  background-image: url(${SliceMobileBgImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    background-position: center;
    background-image: url(${SliceDesktopBgImage});
  }
`;

const Slide2 = styled(Slide)`
  @media only screen and (min-width: ${defaultMaxWidth}px) {
    background: linear-gradient(
      to bottom,
      ${slide2Color} calc(40% + 48px),
      ${({ theme }) => theme.color.white} calc(40% - 48px)
    );
  }
`;

const Slide3 = styled(Slide)`
  @media only screen and (min-width: ${defaultMaxWidth}px) {
    background: linear-gradient(
      to bottom,
      ${slide3Color} calc(40% + 48px),
      ${({ theme }) => theme.color.white} calc(40% - 48px)
    );
  }
`;

const Slide4 = styled(Slide)`
  @media only screen and (min-width: ${defaultMaxWidth}px) {
    background: linear-gradient(
      to bottom,
      ${slide4Color} calc(40% + 48px),
      ${({ theme }) => theme.color.white} calc(40% - 48px)
    );
  }
`;

const FooterWrapper = styled.div`
  max-width: 400px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 24px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;

  > * {
    margin: 0 0.5rem;
  }
`;

const DisclaimerSection = styled.div`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-size: 0.875rem;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: ${homePadding}px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
  }
`;
const DisclaimerLink = styled.a`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  padding-left: 4px;
`;
