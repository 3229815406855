import Modal from 'antd/es/modal/Modal';
import React from 'react';

export type ConfirmCancelModalProps = {
  showModal: boolean;
  onCancel: () => void;
  onOk: () => void;
};

export const confirmCancelTitle = 'Unsaved Changes';
export const confirmCancelDescription =
  'There are unsaved changes. Do you wish to discard them?';

const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = ({
  showModal,
  onCancel,
  onOk,
}) => {
  return (
    <Modal
      open={showModal}
      onOk={onOk}
      onCancel={onCancel}
      title={<h2>{confirmCancelTitle}</h2>}
      okText="Discard"
      cancelText="Cancel"
    >
      <span>{confirmCancelDescription}</span>
    </Modal>
  );
};

export default ConfirmCancelModal;
