import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  Footer,
  Header,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { useMediaQuery, useMultiPageForm } from '../../../hooks';
import { onboardingFormPageTheme } from '../shared/constants';
import { ContentWrapper, FormPageWrapper } from '../shared/components';
import Modal from 'antd/es/modal/Modal';
import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import { mockEndpoint } from '../shared/utils';
import styled from '@emotion/styled';
import {
  INPUT_WIDTH_DESKTOP,
  INPUT_WIDTH_MOBILE,
} from '../../../lib/constants';

const pageTitleRow1 = 'How do you pay';
const pageTitleRow2 = 'rent today?';

type PaymentOption = {
  title: string;
  description: string;
  value: string;
};

const unsupportedPaymentOptions = new Set(['cash']);

const paymentOptions: PaymentOption[] = [
  {
    title: 'Rental portal',
    description:
      'Examples of common rental portals include RentCafe and Appfolio',
    value: 'rental_portal',
  },
  {
    title: 'Bank-linked method',
    description:
      'Including check, ACH, bank transfer and digital apps such as PayPal and Cash App',
    value: 'linked_bank',
  },
  {
    title: 'Cash or money order',
    description: 'Any non-digital methods, also including cashier’s check',
    value: 'cash',
  },
];

type UnsupportedRentalPaymentModalProps = {
  showModal: boolean;
  onCancel: () => void;
  onOk: () => void;
};

const UnsupportedRentalPaymentModal: React.FC<
  UnsupportedRentalPaymentModalProps
> = ({ showModal, onCancel, onOk }) => {
  return (
    <Modal
      open={showModal}
      onOk={onOk}
      onCancel={onCancel}
      title={
        <UnsupportedPaymentEmoji>
          <span>😔</span>
        </UnsupportedPaymentEmoji>
      }
      okText="Email me when available"
    >
      <UnsupportedPaymentContent>
        <h2>Sorry, we don’t support that payment method</h2>
        <p>
          Slice doesn’t currently work with non-digital rent payment methods. We
          are actively working to release this functionality soon.
        </p>
      </UnsupportedPaymentContent>
    </Modal>
  );
};

const RentalPaymentPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const { currentPage, totalPages, setPreviousPage, setCurrentPage } =
    useMultiPageForm();
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [paymentOption, setPaymentOption] = useState<string>();
  const [showUnsupportedPaymentModal, setShowUnsupportedPaymentModal] =
    useState<boolean>(false);

  const openConfirm = () => {
    const isTouched = !!paymentOption;

    if (isTouched) {
      setshowCancelModal(true);
    } else {
      setPreviousPage();
    }
  };

  const submitForm = async () => {
    await mockEndpoint();
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (unsupportedPaymentOptions.has(paymentOption || '')) {
      setShowUnsupportedPaymentModal(true);
    } else {
      setShowUnsupportedPaymentModal(false);
    }
  }, [paymentOption]);

  const Content = (
    <PaymentSelection>
      {paymentOptions.map(({ title, description, value }) => {
        return (
          <PaymentOptionContainer
            key={value}
            className={paymentOption === value ? 'selected' : ''}
            onClick={() => setPaymentOption(value)}
          >
            <PaymentOptionTitle>{title}</PaymentOptionTitle>
            <p>{description}</p>
          </PaymentOptionContainer>
        );
      })}
    </PaymentSelection>
  );

  return (
    <FormPageWrapper ref={ref}>
      <UnsupportedRentalPaymentModal
        showModal={showUnsupportedPaymentModal}
        onCancel={() => setShowUnsupportedPaymentModal(false)}
        onOk={() => {
          setShowUnsupportedPaymentModal(false);
        }}
      />
      <ConfirmCancelModal
        showModal={showCancelModal}
        onCancel={() => setshowCancelModal(false)}
        onOk={() => {
          setshowCancelModal(false);
          setPreviousPage();
        }}
      />
      <ResponsiveLayout
        pageTheme={onboardingFormPageTheme}
        header={<Header titleRow1={pageTitleRow1} titleRow2={pageTitleRow2} />}
        content={<ContentWrapper>{Content}</ContentWrapper>}
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                textDecoration: 'underline',
                disabled: currentPage === 0,
                onClick: openConfirm,
              },
              {
                text: 'Next',
                fill: 'filled',
                variant: 'primary',
                disabled: !paymentOption,
                onClick: submitForm,
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
});

const PaymentSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PaymentOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  width: ${INPUT_WIDTH_MOBILE}px;
  height: 150px;
  width: 100%;
  max-width: 450px;
  border-radius: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 16px;
  background: #ffffff;
  color: ${({ theme }) => theme.colorMap.neutral.black};
  transition: border 0.25s linear;

  &:nth-of-type(2n) {
    margin: 1.5rem 0rem;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colorMap.neutral.black};
    cursor: pointer;
  }

  &.selected {
    border: 2px solid ${({ theme }) => theme.colorMap.neutral.black};
    background: #f4f4f4;
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: ${INPUT_WIDTH_DESKTOP}px;
  }
`;

const PaymentOptionTitle = styled.span`
  font-weight: 600;
  font-size: 1.125rem;
`;

export const UnsupportedPaymentEmoji = styled.div`
  width: 80px;
  height: 80px;
  background: #faf0da;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

export const UnsupportedPaymentContent = styled.div`
  font-size: 1rem;

  h2 {
    max-width: 300px;
  }
`;

export default RentalPaymentPage;
