import { apiClient } from '../../hooks';
import {
  Lease,
  OnboardingUserDetails,
  PlaidUser,
  WaitlistUser,
} from '../../types';

export type OnboardingNameAndEmailRequest = {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type OnboardingSubmitAddress = {
  userId: string;
  address: {
    address1: string;
    address2?: string;
    city: string;
    stateCode: string;
    postalCode: string;
    postalCode2?: string;
    countryCode: string;
  };
};

export type OnboardingSubmitKYCRequest = {
  userId: string;
  dob: string;
  socialSecurityNumber: string;
};

export type OnboardingVerifyKycStatus = {
  userId: string;
};

export type PlaidCreateLinkTokenRequest = {
  userId: string;
  mobileNumber: string;
};

export type PlaidLinkTokenResponse = {
  token: string;
};

export type PlaidCreateUserRequest = {
  userId: string;
  accessToken: string;
  accountId: string;
};

export type AddToWaitlistRequest = {
  userId: string;
};

export type LeaseCreateRequest = {
  userId: string;
  monthlyRent: number;
};

export type OnboardingService = {
  getUserDetails: (userId: string) => Promise<OnboardingUserDetails>;
  submitNameAndEmail: (
    body: OnboardingNameAndEmailRequest
  ) => Promise<OnboardingUserDetails>;
  submitAddress: (
    body: OnboardingSubmitAddress
  ) => Promise<OnboardingUserDetails>;
  // TODO: encode social security number so it doesn't end up in logging
  submitKyc: (
    body: OnboardingSubmitKYCRequest
  ) => Promise<OnboardingUserDetails>;
  verifyKyc: (
    body: OnboardingVerifyKycStatus
  ) => Promise<OnboardingUserDetails>;
  createPlaidLinkToken: (
    body: PlaidCreateLinkTokenRequest
  ) => Promise<PlaidLinkTokenResponse>;
  createPlaidUser: (body: PlaidCreateUserRequest) => Promise<PlaidUser>;
  getPlaidUser: (userId: string) => Promise<PlaidUser>;
  addToWaitlist: (body: AddToWaitlistRequest) => Promise<WaitlistUser>;
  getWaitlistedUser: (userId: string) => Promise<WaitlistUser>;
  getLease: (userId: string) => Promise<Lease>;
  submitLeaseMonthlyRent: (body: LeaseCreateRequest) => Promise<Lease>;
};

export const onboardingServiceFactory = (
  apiClient: apiClient
): OnboardingService => ({
  getUserDetails: (userId: string): Promise<OnboardingUserDetails> =>
    apiClient<OnboardingUserDetails>(`/onboarding/get-user-details`, {
      method: 'GET',
      body: { user_id: userId },
    }),
  submitNameAndEmail: (
    body: OnboardingNameAndEmailRequest
  ): Promise<OnboardingUserDetails> =>
    apiClient<OnboardingUserDetails>(`/onboarding/submit-name-and-email`, {
      method: 'PUT',
      body,
    }),
  submitAddress: (
    body: OnboardingSubmitAddress
  ): Promise<OnboardingUserDetails> =>
    apiClient<OnboardingUserDetails>(`/onboarding/submit-address`, {
      method: 'PUT',
      body,
    }),
  submitKyc: (
    body: OnboardingSubmitKYCRequest
  ): Promise<OnboardingUserDetails> =>
    apiClient<OnboardingUserDetails>(`/onboarding/submit-kyc`, {
      method: 'PUT',
      body,
    }),
  verifyKyc: (
    body: OnboardingVerifyKycStatus
  ): Promise<OnboardingUserDetails> =>
    apiClient<OnboardingUserDetails>(`/onboarding/verify-kyc`, {
      method: 'PUT',
      body,
    }),
  createPlaidLinkToken: (
    body: PlaidCreateLinkTokenRequest
  ): Promise<PlaidLinkTokenResponse> =>
    apiClient<PlaidLinkTokenResponse>(`/onboarding/create-plaid-link-token`, {
      method: 'POST',
      body,
    }),
  createPlaidUser: (body: PlaidCreateUserRequest): Promise<PlaidUser> =>
    apiClient<PlaidUser>(`/onboarding/create-plaid-user`, {
      method: 'POST',
      body,
    }),
  getPlaidUser: (userId: string): Promise<PlaidUser> =>
    apiClient<PlaidUser>(`/onboarding/get-plaid-user`, {
      method: 'GET',
      body: { user_id: userId },
    }),
  addToWaitlist: (body: AddToWaitlistRequest): Promise<WaitlistUser> =>
    apiClient<WaitlistUser>(`/onboarding/add-to-waitlist`, {
      method: 'POST',
      body,
    }),
  getWaitlistedUser: (userId: string): Promise<WaitlistUser> =>
    apiClient<WaitlistUser>(`/onboarding/get-waitlisted-user`, {
      method: 'GET',
      body: { user_id: userId },
    }),
  getLease: (userId: string): Promise<Lease> =>
    apiClient<Lease>(`/onboarding/get-lease`, {
      method: 'GET',
      body: { user_id: userId },
    }),
  submitLeaseMonthlyRent: (body: LeaseCreateRequest): Promise<Lease> =>
    apiClient<Lease>(`/onboarding/submit-lease-monthly-rent`, {
      method: 'PUT',
      body,
    }),
});
