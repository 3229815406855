import React from 'react';
import {
  Footer,
  Header,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import {
  useFormPage,
  useMediaQuery,
  useMultiPageForm,
  useService,
} from '../../../hooks';
import { useTheme } from '@emotion/react';
import { onboardingFormPageTheme } from '../shared/constants';
import { ContentWrapper, FormPageWrapper } from '../shared/components';
import { useOnboarding } from '../../../hooks/useOnboarding';
import { Lease } from '../../../types';
import { Form, FormInstance, InputNumber } from 'antd';
import { SupportTooltip } from '../../../components';
import { centToDollar, dollarToCent } from '../../../lib/utils';
import { LeaseCreateRequest } from '../../../lib/services/onboarding';

const pageTitleRow1 = 'What’s your monthly rent?';
const pageDescription =
  'This should be similar to what comes out of your account every month.';
const tooltipContent =
  'Don’t worry if your rent payment fluctuates due to utilities and other charges.';

type MonthlyRentFormValues = {
  monthlyRent?: number;
};

const MonthlyRentPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const {
    currentPage,
    totalPages,
    setPreviousPage,
    setNextPage,
    onPageSubmit,
  } = useMultiPageForm();
  const { details, lease, setLease } = useOnboarding();

  const onboardingService = useService<'onboarding'>('onboarding');

  const { form, onChange, hasChanges, openConfirm } = useFormPage<
    Lease,
    MonthlyRentFormValues
  >({ data: lease, toFormValues });

  const submitForm = async () => {
    await form.validateFields();

    if (!hasChanges()) {
      setNextPage();
      return;
    }

    const requestBody = toRequestBody(form, details.userId);
    const request = onboardingService.submitLeaseMonthlyRent(requestBody);

    await onPageSubmit(request)
      .then((res) => {
        setLease(res);
        setNextPage();
      })
      .catch((e) => {
        // TODO: handle
        return null;
      });
  };

  const MonthlyRentForm = (
    <Form form={form} onChange={onChange} layout="vertical">
      <Form.Item
        className={`slice`}
        name="monthlyRent"
        label="Monthly Rent"
        rules={[{ required: true, message: 'Monthly rent is required' }]}
        hasFeedback
      >
        <InputNumber
          controls={false}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        />
      </Form.Item>
      <SupportTooltip tooltipContent={tooltipContent} />
    </Form>
  );

  return (
    <FormPageWrapper ref={ref}>
      <ResponsiveLayout
        pageTheme={onboardingFormPageTheme}
        header={
          <Header
            titleRow1={pageTitleRow1}
            description={pageDescription}
            animate={true}
          />
        }
        content={<ContentWrapper>{MonthlyRentForm}</ContentWrapper>}
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                textDecoration: 'underline',
                disabled: currentPage === 0,
                onClick: setPreviousPage,
              },
              {
                text: 'Next',
                fill: 'filled',
                variant: 'primary',
                disabled: false,
                onClick: submitForm,
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
});

export default MonthlyRentPage;

// Helpers
const toFormValues = (lease: Lease): MonthlyRentFormValues => {
  return {
    monthlyRent: lease.monthlyRent
      ? centToDollar(lease.monthlyRent)
      : undefined,
  };
};

const toRequestBody = (
  form: FormInstance<any>,
  userId: string
): LeaseCreateRequest => {
  return {
    userId: userId,
    monthlyRent: dollarToCent(form.getFieldValue('monthlyRent')!!),
  };
};
