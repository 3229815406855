import { FormInstance } from 'antd';

export const mockEndpoint = (error?: boolean): Promise<any> => {
  if (error) {
    return Promise.reject('Error');
  } else {
    return Promise.resolve();
  }
};

export const hasRequiredFields = (form: FormInstance): boolean => {
  const allFields = form.getFieldsValue();

  let isValid = true;

  Object.keys(allFields).forEach((k) => {
    const v = allFields[k];
    const formItem = form.getFieldInstance(k);

    const input = formItem?.input ? formItem.input : document.getElementById(k);

    if (input && input.getAttribute('aria-required')) {
      const hasValue = v !== undefined && v !== null && v !== '';

      if (!hasValue) {
        isValid = false;
      }
    }
  });

  return isValid;
};

export const hasErrors = (form: FormInstance): boolean => {
  const fieldsWithError = form
    .getFieldsError()
    .filter((item) => item.errors.length > 0);
  return fieldsWithError.length > 0;
};

export const isValid = (form: FormInstance) =>
  hasRequiredFields(form) && !hasErrors(form);

export const hasChanges = (form: FormInstance, initialValues: any) =>
  JSON.stringify(form.getFieldsValue()) !== JSON.stringify(initialValues);

export function debounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  delay: number
): (...args: Parameters<F>) => Promise<any> {
  let timer: number | null = null;

  return function (...args: Parameters<F>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = window.setTimeout(() => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, delay);
    });
  };
}

export const onboardingPageAnimations: { duration; delayStep; animationName } =
  {
    duration: 1,
    delayStep: 0.1,
    animationName: 'fadeIn',
  };
