import styled from '@emotion/styled';
import {
  DatePicker,
  DatePickerProps,
  FormItemProps,
  Input,
  InputProps,
} from 'antd';
import React from 'react';

export enum SliceFormFieldInputKind {
  ADDRESS_AUTO_COMPLETE,
  TEXT,
  NUMBER,
  DATE,
  PASSWORD,
  SOCIAL_SECURITY,
}

export interface SliceFormFieldProps {
  key: string;
  inputKind: SliceFormFieldInputKind;
  isCustomComponent?: boolean;
  inputGroupProps?: FormItemProps;
  inputProps?: SliceInputProps;
}

export type SliceInputProps = {
  inputIconWidth?: number;
} & InputProps;

export type SliceDateInputProps = {
  inputIconWidth?: number;
} & DatePickerProps;

const SliceTextInput: React.FC<SliceInputProps> = (props) => (
  <Input {...props} />
);
const SliceNumberInput: React.FC<SliceInputProps> = (props) => (
  <Input type="number" {...props} />
);
const SliceDateInput: React.FC<SliceDateInputProps> = ({
  inputIconWidth = 28,
  ...props
}) => <StyledDatePicker {...props} inputIconWidth={inputIconWidth} />;

export { SliceTextInput, SliceNumberInput, SliceDateInput };

const StyledDatePicker = styled(DatePicker)<{ inputIconWidth: number }>`
  width: 100%;

  .ant-picker-input {
    min-height: 50px;

    input {
      border: 1px solid transparent !important;
    }
    span {
      svg {
        width: ${(props) => props.inputIconWidth}px;
        height: ${(props) => props.inputIconWidth}px;
        // hack looks more visually aligned to eye icon
        margin-right: 1px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
