/* @jsxImportSource @emotion/react */
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import styled from '@emotion/styled';
import { Form, Input } from 'antd';
import { Icons } from '@slicepayments/web-components';
import { INPUT_WIDTH_DESKTOP, INPUT_WIDTH_MOBILE } from '../lib/constants';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: Array<string>;
};

export const extractAddressData =
  (components: Array<AddressComponent>) =>
  (type: string, version: 'long_name' | 'short_name' = 'long_name'): string =>
    components.find(({ types }) => types.includes(type))?.[version] || '';

type AddressAutocompleteProps = {
  onChange: (object) => void;
  error?: {
    message: string;
  };
  defaultValue?: any;
  className?: string;
};

const AddressAutocomplete = ({
  onChange,
  error,
  defaultValue,
  className,
}: AddressAutocompleteProps) => {
  const [address, setAddress] = React.useState(defaultValue || '');

  const handleChange = (address) => {
    setAddress(address);

    if (!address) {
      onChange(address);
    }
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address).then(onChange);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      highlightFirstSuggestion={true}
      searchOptions={{
        componentRestrictions: {
          country: ['us'],
        },
      }}
    >
      {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
        <div css={{ position: 'relative' }}>
          <Form.Item
            className={`slice ${className}`}
            name="address1"
            label="Street address"
            hasFeedback
            rules={[{ required: true }]}
          >
            <Input
              {...getInputProps({ placeholder: 'Enter street address' })}
              defaultValue={defaultValue}
              error={error}
              autoFocus
            />
          </Form.Item>
          {(loading || suggestions.length > 0) && (
            <AddressAutocompleteSuggestions>
              {suggestions.map((suggestion) => (
                <AddressAutocompleteSuggestion
                  className={
                    suggestion.active &&
                    'address-autocomplete-suggestion--active'
                  }
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <Icons.Maps.PinPlus />
                  <AddressAutocompleteTextContainer>
                    <span>{suggestion.formattedSuggestion?.mainText}</span>
                    <br />
                    {suggestion.formattedSuggestion?.secondaryText}
                  </AddressAutocompleteTextContainer>
                </AddressAutocompleteSuggestion>
              ))}
            </AddressAutocompleteSuggestions>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;

const AddressAutocompleteSuggestions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: ${INPUT_WIDTH_MOBILE}px;
  z-index: ${({ theme }) => theme.zIndices.middle};
  background: white;

  &:empty:before {
    display: block;
    content: 'Loading...';
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: ${INPUT_WIDTH_DESKTOP}px;
  }
`;

const AddressAutocompleteSuggestion = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem;
  width: 100%;
  color: ${({ theme }) => theme.colorMap.neutral.black};
  font-size: 1rem;
  font-family: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};

  svg {
    flex-basis: 10%;
  }

  &.address-autocomplete-suggestion--active {
    background-color: rgba(0, 200, 5, 0.1);
  }
`;

const AddressAutocompleteTextContainer = styled.div`
  flex: 1;
  line-height: 100%;

  span {
    font-weight: 500;
  }
`;
