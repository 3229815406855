import { HttpError } from '../types';
import { encodeBody } from './utils';

export class ApiUnauthorizedError extends Error {}
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type FetchOptions = {
  method?: HTTPMethod;
  body?: Record<string, any>;
  headers?: Record<string, string>;
  paginationCacheKey?: string;
};

type ApiRequestOptions = {
  token: string;
  userId?: string;
};

const snakeCaseToCamelCase = (data: any): any => {
  // base case: pass through primitive values
  switch (typeof data) {
    case 'string':
    case 'boolean':
    case 'number':
      return data;
    case 'object':
      if (data === null) return data;
  }

  // for arrays, map over values in order to convert objects
  if (Array.isArray(data)) return data.map(snakeCaseToCamelCase);

  return Object.entries(data)
    .map(([k, v]: [string, any]): [string, any] => [
      k.replace(/_(.)/g, (_, $1) => $1.toUpperCase()),
      snakeCaseToCamelCase(v),
    ])
    .reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
};

const keysToSnakeCase = (obj: any): any => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(keysToSnakeCase);
  }

  if (typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        const newKey = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        );
        return [newKey, keysToSnakeCase(value)];
      })
    );
  }

  return obj;
};

// Safari does not support lookbehind assertion in regex,
// @see https://caniuse.com/js-regexp-lookbehind
// so this function takes some extra steps to deal with slashes in URLs.
// Admittedly this may be over-engineered, but it's worth it so that
// devs can `request('path/to/resource')` and `request('/path/to/resource')`.
const buildUrl = (path: string): string =>
  (process.env.REACT_APP_SLICE_API_HOST || '').replace(/\/$/g, '') +
  `/${path}`.replace(/\/{2,}/, '/');

function request(
  path: string,
  {
    method = 'GET',
    body,
    headers,
    paginationCacheKey,
    ...options
  }: FetchOptions = {},
  { token, userId }: ApiRequestOptions
) {
  const isGetRequest = method.toLowerCase() === 'get';

  const query: string = isGetRequest && body ? '?' + encodeBody(body) : '';

  return fetch(buildUrl(path + query), {
    method,
    body: isGetRequest ? null : JSON.stringify(keysToSnakeCase(body || {})),
    ...options,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      ...(userId ? { 'x-slice-id': userId } : null),
    },
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          return Promise.reject(new ApiUnauthorizedError());
        }
        const error: HttpError = {
          statusCode: response.status,
          message: response.statusText,
          name: response.status.toString(),
        };
        return Promise.reject(error);
      }

      return response;
    })
    .then((response) => {
      // TODO: figure out pagination for slice
      return response.json();
    })
    .then(snakeCaseToCamelCase);
}

export { request, snakeCaseToCamelCase };
