import React from 'react';
import {
  DynamicAnimation,
  Footer,
  Header,
  NavBar,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { useFormPage, useMultiPageForm, useService } from '../../../hooks';
import { FormPageWrapper, ContentWrapper } from '../shared/components';
import { ConfirmCancelModal } from '../../../components';
import { useOnboarding } from '../../../hooks/useOnboarding';
import { OnboardingUserDetails } from '../../../types';
import { FormInstance } from 'antd/lib/form';
import { OnboardingNameAndEmailRequest } from '../../../lib/services/onboarding';
import { onboardingPageAnimations } from '../shared/utils';
import useLayout, {
  LayoutPropsByDevice,
  pageLayoutPropsByKind,
} from '../../../hooks/useLayout';
import { UserEmailForm } from '../forms';
import SliceBuildCredit from '../assets/slice-build-credit.svg';
import { OnboardingPageProps } from '../shared/types';

// Types
type EmailFormValues = {
  email?: string;
};

const pageLayoutProps: LayoutPropsByDevice = {
  desktop: {
    ...pageLayoutPropsByKind.form.desktop,
    pageTheme: {
      bgColor: '#98C5F6',
      image: SliceBuildCredit,
    },
  },
  mobile: {
    ...pageLayoutPropsByKind.form.mobile,
  },
};

const { animationName, duration, delayStep } = onboardingPageAnimations;

// Page
const UserEmailPage = React.forwardRef<HTMLDivElement, OnboardingPageProps>(
  ({ pageIndex }, ref) => {
    const { pageTheme, titleFontSize, textAlign } = useLayout(pageLayoutProps);

    const {
      currentPage,
      isTransitioning,
      setPreviousPage,
      setNextPage,
      onPageSubmit,
    } = useMultiPageForm();

    const { details, setDetails } = useOnboarding();
    const onboardingService = useService<'onboarding'>('onboarding');

    const {
      form,
      showCancelModal,
      isFormValid,
      setShowCancelModal,
      onChange,
      hasChanges,
    } = useFormPage<OnboardingUserDetails, EmailFormValues>({
      data: details,
      toFormValues,
    });

    const submitForm = async () => {
      await form.validateFields();

      if (!hasChanges()) {
        setNextPage();
        return;
      }

      const requestBody = toRequestBody(form, details);
      const request = onboardingService.submitNameAndEmail(requestBody);

      await onPageSubmit(request)
        .then((res) => {
          setDetails(res);
          setNextPage();
        })
        .catch((e) => {
          // TODO: handle
          return null;
        });
    };

    const nav = <NavBar handleClick={setPreviousPage} kind="navigation" />;
    const header = (
      <Header
        titleRow1={'What is your email address?'}
        titleFontSize={titleFontSize}
        textAlign={textAlign}
        animate={true}
        animationName={animationName}
        animationDurationSeconds={duration}
        animationDelayStepSeconds={delayStep}
        animationInitialDelaySeconds={delayStep}
      />
    );

    const footer = (
      <Footer
        buttons={[
          {
            text: 'Back',
            fill: 'filled',
            variant: 'white',
            border: 'border',
            size: 'small',
            disabled: currentPage === 0 || currentPage !== pageIndex,
            onClick: setPreviousPage,
          },
          {
            text: 'Next',
            fill: 'filled',
            variant: 'primary',
            size: 'small',
            type: 'submit',
            loading: currentPage > pageIndex || isTransitioning,
            disabled: !isFormValid,
            onClick: submitForm,
          },
        ]}
      />
    );

    const content = (
      <ContentWrapper>
        <DynamicAnimation
          animationName={animationName}
          animationDurationSeconds={duration}
          animationDelaySeconds={delayStep * 2}
        >
          <UserEmailForm form={form} onChange={onChange} />
        </DynamicAnimation>
      </ContentWrapper>
    );

    return (
      <FormPageWrapper ref={ref}>
        <ConfirmCancelModal
          showModal={showCancelModal}
          onCancel={() => setShowCancelModal(false)}
          onOk={() => {
            setShowCancelModal(false);
            setPreviousPage();
          }}
        />
        <ResponsiveLayout
          nav={nav}
          pageTheme={pageTheme}
          header={header}
          content={content}
          footer={footer}
        />
      </FormPageWrapper>
    );
  }
);

export default UserEmailPage;

// Helpers
const toFormValues = (details: OnboardingUserDetails): EmailFormValues => {
  return {
    email: details?.email,
  };
};

const toRequestBody = (
  form: FormInstance<any>,
  details: OnboardingUserDetails
): OnboardingNameAndEmailRequest => {
  return {
    userId: details.userId,
    firstName: details.firstName,
    lastName: details.lastName,
    email: form.getFieldValue('email'),
  };
};
