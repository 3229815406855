import React from 'react';
import { FormProps } from 'antd/lib/form';
import { StyledForm } from '../shared/components';
import { Form, Input } from 'antd';

// Types
export interface UserDetailsFormProps extends FormProps {
  form: any;
  onChange: any;
  initialValues?: any;
}

// Component
const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  form,
  onChange,
  initialValues = {},
}) => {
  return (
    <StyledForm
      form={form}
      initialValues={initialValues}
      onChange={onChange}
      validateTrigger="onSubmit"
      layout="vertical"
    >
      <Form.Item
        className={`slice`}
        name="firstName"
        label="First name"
        rules={[{ required: true, message: 'First name is required' }]}
        hasFeedback
      >
        <Input placeholder="Enter legal first name" />
      </Form.Item>
      <Form.Item
        className={`slice`}
        name="lastName"
        label="Last name"
        rules={[{ required: true, message: 'Last name is required' }]}
        hasFeedback
      >
        <Input placeholder="Enter legal last name" />
      </Form.Item>
    </StyledForm>
  );
};

export default UserDetailsForm;
