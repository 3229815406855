import { HttpError } from '../types/HttpError';

export const getReadableDate = (date: string): string => {
  const d = new Date(date);
  const today = new Date();

  if (d.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return 'Now';
  } else {
    return d.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
    });
  }
};

export const getReadableMonth = (date: string): string => {
  const d = new Date(date);
  const today = new Date();

  if (d.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return 'Now';
  } else {
    return d.toLocaleString('default', {
      month: 'long',
    });
  }
};

export const rangeTo = (start: number, end: number): number[] => {
  const keys = new Array(end + 1 - start).keys();
  return [...Array.from(keys)];
};

export const sortKeys = (
  obj: any
): Record<string, string | number | boolean | undefined | any> => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key],
      }),
      {}
    );
};

export const encodeBody = (
  body: Record<string, string | number | boolean | undefined | any>
) => {
  return Object.entries(sortKeys(body))
    .flatMap((pair) => {
      const [k, v] = pair;

      if (v === undefined || v === null) {
        return [];
      }

      if (typeof v === 'object') {
        return (v as any[]).map((val) =>
          [k, encodeURIComponent(val)].join('=')
        );
      }

      return [k, encodeURIComponent(v)].join('=');
    })
    .join('&');
};

export const toMapWithInitialValue = <K, V>(arr: K[], initialValue: V) => {
  return arr.reduce((prev, curr) => {
    const newObject = {};
    newObject[`${curr}`] = initialValue;
    return { ...prev, ...newObject };
  }, {});
};

export const associate = <T, K extends keyof T, V extends keyof T>(
  arr: T[],
  k: K,
  v: V
) => {
  return arr.reduce((prev, curr) => {
    const key = curr[k];
    const value = curr[v];
    const newObject = {
      [`${key}`]: value,
    };
    return { ...prev, ...newObject };
  }, {});
};

export const capitalize = (str: string): string => {
  if (!str || str.length <= 1) {
    return str as string;
  } else {
    return `${str[0].toUpperCase()}${str.slice(1, str.length).toLowerCase()}`;
  }
};
export const fullToAbbreviatedStateMap: Record<string, string> = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const abbreviateState = (fullStateName?: string): string => {
  if (!fullStateName) return '';

  return fullToAbbreviatedStateMap[fullStateName] || fullStateName;
};

export const parseQueryParams = (params = {}) => {
  return Object.keys(params)
    .filter((key) => {
      return (
        params[key] !== undefined ||
        params[key] !== null ||
        (typeof params[key] === 'object' && params[key]?.length > 0)
      );
    })
    .flatMap((key) => {
      return typeof params[key] === 'object'
        ? params[key].map((k) => key + '=' + k)
        : key + '=' + params[key];
    })
    .sort((a, b) => a - b)
    .join('&');
};

// hack: google's autocomplete gives cities in the form
// "Los Angeles, CA, USA" -- but the API expects just the
// name of the city
export const toShortCityName = (result: string) => result?.replace(/,.*$/, '');

export const maskAsterisk = '*';

export type SSNumberResponse = {
  ss: string;
  ssMasked: string;
};

export const validateAndMaskSSNumberInputValue = (
  changedValue: any,
  currentActualSS: string
): SSNumberResponse => {
  const patt = new RegExp('d{3}[-]d{2}[-]d{4}');
  // const actualSS = form.getFieldValue('ss');
  const swappedMaskAsteriskForActualNumber = changedValue
    .split('')
    .map((char, idx) => {
      if (char === maskAsterisk) {
        return currentActualSS[idx];
      } else {
        return char;
      }
    })
    .join('');

  if (patt.test(swappedMaskAsteriskForActualNumber)) {
    return { ss: '', ssMasked: '' };
  } else {
    const newActualSS = swappedMaskAsteriskForActualNumber
      .match(/\d*/g)
      .join('')
      .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
      .slice(1)
      .join('-')
      .replace(/-*$/g, '');

    return {
      ss: newActualSS,
      ssMasked: newActualSS.replace(/[0-9]/g, maskAsterisk),
    };
  }
};

export const centToDollar = (num: number) => num / 100;

export const dollarToCent = (num: number) => num * 100;

// backoff with retry
export const exponentialBackoffRetry = (retryAttempt: number) => {
  const maxBackoffMilli = 30000; // 30 seconds
  const backoffMilli = Math.pow(2, retryAttempt) * 1000;
  return Math.min(backoffMilli, maxBackoffMilli);
};

// Retry api request
export const retryIf = (failureCount: number, error: HttpError): boolean => {
  const maxFailureCount = 3;
  if (error.statusCode === 500 || error.statusCode === 404) {
    return false;
  } else {
    return failureCount < maxFailureCount - 1;
  }
};
