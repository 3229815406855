export enum OnboardingStep {
  WELCOME = 'WELCOME',
  USER_LEGAL_NAME = 'USER_LEGAL_NAME',
  USER_EMAIL = 'USER_EMAIL',
  ADDRESS = 'ADDRESS',
  KYC = 'KYC',
  RENTAL_PAYMENT = 'RENTAL_PAYMENT',
  LINK_RENT_PORTAL = 'LINK_RENT_PORTAL',
  LINK_BANK_INFO = 'LINK_BANK_INFO',
  WAITLIST = 'WAITLIST',
  MONTHLY_RENT = 'MONTHLY_RENT',
  MONTHLY_RENT_DUE_AT = 'MONTHLY_RENT_DUE_AT',
  LEASE_DETAILS = 'LEASE_DETAILS',
  LANDLORD_DETAILS = 'LANDLORD_DETAILS',
  UPLOAD_LEASE = 'UPLOAD_LEASE',
  FORM_CONFIRMATION = 'FORM_CONFIRMATION',
  ENROLLMENT_CONFIRMATION = 'ENROLLMENT_CONFIRMATION',
  FINISH_ONBOARDING_SCREEN = 'FINISH_ONBOARDING_SCREEN',
}

export interface OnboardingFormProps {
  form: any;
  initialValues: any;
  onValuesChange: any;
  onChange: any;
}

export interface OnboardingPageProps {
  pageIndex: number;
}
