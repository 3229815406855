import React, { FocusEventHandler, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  ResponsiveLayout,
  Footer,
  Header,
} from '@slicepayments/web-components';
import { DatePicker, DatePickerProps, Form, Input } from 'antd';
import {
  useFormPage,
  useMediaQuery,
  useMultiPageForm,
  useService,
} from '../../../hooks';
import { onboardingFormPageTheme } from '../shared/constants';
import { ContentWrapper, FormPageWrapper } from '../shared/components';
import {
  ConfirmCancelModal,
  MaskedInputFieldButton,
} from '../../../components';
import { FormInstance, Rule } from 'antd/es/form';
import { validateAndMaskSSNumberInputValue } from '../../../lib/utils';
import { OnboardingUserDetails } from '../../../types';
import { OnboardingSubmitKYCRequest } from '../../../lib/services/onboarding';
import { useOnboarding } from '../../../hooks/useOnboarding';
import dayjs from 'dayjs/esm';
import 'dayjs/locale/en';

// Types
type KycFormValues = {
  dob: string;
  ss: string;
  ssMasked: string;
};

// Constants
const pageTitleRow1 = 'Verify your identity';
const pageDescription =
  'This information is required by our financial partners to prevent fraud.';
const socialSecurityRules: Rule[] = [
  {
    required: true,
    pattern: /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
    message: 'Must be valid social security number',
  },
];
const inputIconWidth = 24;
const validDOBFormats = ['MM/DD/YYYY', 'MM/DD/YY'];

// KycPage
const KycPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const { details, setDetails } = useOnboarding();
  const onboardingService = useService<'onboarding'>('onboarding');
  const {
    currentPage,
    totalPages,
    setPreviousPage,
    setNextPage,
    onPageSubmit,
  } = useMultiPageForm();

  const {
    form,
    showCancelModal,
    isFormValid,
    setShowCancelModal,
    openConfirm,
    onChange,
    hasChanges,
  } = useFormPage<OnboardingUserDetails, KycFormValues>({
    data: details,
    toFormValues,
  });

  const [maskSS, setMaskSS] = useState<boolean>(true);

  const onSSNumberChange: FocusEventHandler<any> = (evt) => {
    const currentSSNumber = form.getFieldValue('ss');
    const { ss, ssMasked } = validateAndMaskSSNumberInputValue(
      evt.target.value,
      currentSSNumber
    );
    form.setFieldValue('ss', ss);
    form.setFieldValue('ssMasked', ssMasked);
  };

  const onDateChange: (
    value: dayjs.Dayjs | null,
    dateString: string
  ) => void = (date) => {
    // TODO --> WE SHOULD CHANGE BACKEND TYPE TO DATE INSTEAD OF TIMESTAMP
    if (date) {
      form.setFieldValue('dob', date.startOf('day').toJSON());
    }

    onChange();
  };

  const submitForm = async () => {
    // Go to next page if there's no changes
    if (!hasChanges()) {
      setNextPage();
      return;
    }

    await form.validateFields();

    const requestBody = toRequestBody(form, details.userId);
    const request = onboardingService.submitKyc(requestBody);

    await onPageSubmit(request)
      .then(async () => {
        const res = await checkKycStatus();
        setDetails(res);
        setNextPage();
      })
      .catch((e) => {
        // TODO: handle
        return null;
      });
  };

  const checkKycStatus = async (): Promise<OnboardingUserDetails> => {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        try {
          const res = await onboardingService.verifyKyc({
            userId: details.userId,
          });
          if (
            res?.solidKycStatus &&
            res.solidKycStatus.toLowerCase() === 'approved'
          ) {
            clearInterval(intervalId);
            resolve(res);
          }
        } catch (err) {
          console.error(`API call failed: ${err}`);
          clearInterval(intervalId);
          reject(err);
        }
      }, 1000); // poll every 1 second
    });
  };

  const MaskedButton = (
    <MaskedInputFieldButton
      maskContent={maskSS}
      handleOnClick={() => setMaskSS(!maskSS)}
      iconWidth={inputIconWidth + 4}
    />
  );

  const Content = (
    <Form form={form} onChange={onChange} layout="vertical">
      <Form.Item
        className="slice"
        name="dob"
        label="Date of birth"
        rules={[{ required: true }]}
      >
        <Input name="dob" type="hidden" />
        <StyledDatePicker
          defaultValue={dayjs(form.getFieldValue('dob'))}
          onChange={onDateChange}
          placeholder="mm/dd/yyyy"
          format={validDOBFormats}
          aria-required={true}
        />
      </Form.Item>
      <StyledInputFieldWithIconButton
        hidden={!maskSS}
        className="slice"
        name="ssMasked"
        label="Social Security number"
        rules={[{ required: true }]}
      >
        <Input
          className="masked"
          onChange={onSSNumberChange}
          placeholder="___-__-____"
          suffix={MaskedButton}
        />
      </StyledInputFieldWithIconButton>
      <StyledInputFieldWithIconButton
        hidden={maskSS}
        className="slice"
        name="ss"
        label="Social Security number"
        rules={socialSecurityRules}
      >
        <Input
          onChange={onSSNumberChange}
          placeholder="___-__-____"
          suffix={MaskedButton}
        />
      </StyledInputFieldWithIconButton>
    </Form>
  );

  return (
    <FormPageWrapper ref={ref}>
      <ConfirmCancelModal
        showModal={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onOk={() => {
          setShowCancelModal(false);
          setPreviousPage();
        }}
      />
      <ResponsiveLayout
        pageTheme={onboardingFormPageTheme}
        header={
          <Header titleRow1={pageTitleRow1} description={pageDescription} />
        }
        content={<ContentWrapper>{Content}</ContentWrapper>}
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                textDecoration: 'underline',
                disabled: currentPage === 0,
                onClick: () => openConfirm(setPreviousPage),
              },
              {
                text: 'Next',
                fill: 'filled',
                variant: 'primary',
                disabled: !isFormValid,
                onClick: submitForm,
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
});

export default KycPage;

// Styled components
const StyledDatePicker = styled(DatePicker)`
  width: 100%;

  .ant-picker-input {
    min-height: 50px;

    input {
      border: 1px solid transparent !important;
    }
    span {
      svg {
        width: ${inputIconWidth}px;
        height: ${inputIconWidth}px;
        // hack looks more visually aligned to eye icon
        margin-right: 1px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const StyledInputFieldWithIconButton = styled(Form.Item)`
  .ant-col.ant-form-item-control input {
    border: none !important;
  }
`;

// Helpers
const toFormValues = (details: OnboardingUserDetails): KycFormValues => {
  if (
    details.solidKycStatus &&
    details.solidKycStatus.toLowerCase() === 'approved'
  ) {
    return {
      dob: details.dob || '',
      ssMasked: '***-**-****',
      ss: '***-**-****',
    };
  } else {
    return {
      dob: details.dob || '',
      ssMasked: '***-**-****',
      ss: '***-**-****',
    };
  }
};

const toRequestBody = (
  form: FormInstance<any>,
  userId: string
): OnboardingSubmitKYCRequest => {
  // TODO Encrypt ss number
  return {
    userId: userId,
    dob: form.getFieldValue('dob'),
    socialSecurityNumber: form.getFieldValue('ss')?.replaceAll('-', ''),
  };
};
