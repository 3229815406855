import { extractAddressData } from '../components/AddressAutoComplete';
import { snakeCaseToCamelCase } from '../lib/api';

type GoogleGeocodeCityResponse = {
  city: string;
  state: string;
  stateCode: string;
  postalCode: string;
  postalCode2?: string;
  countryCode: string;
};

type UseGoogleGeocoder = {
  cityByPostalCode: (postalCode: string) => Promise<GoogleGeocodeCityResponse>;
};

const GOOGLE_BASE_URL = 'https://maps.googleapis.com';
const GOOGLE_GEOCODE_API = `${GOOGLE_BASE_URL}/maps/api/geocode`;

const useGoogleGeocoder = (): UseGoogleGeocoder => {
  const extractGeocodeResult = (data): GoogleGeocodeCityResponse => {
    if (data.results.length === 0) throw new Error('Invalid postal code');
    const extract = extractAddressData(data.results[0].address_components);
    const cityResponse = {
      city: extract('locality'),
      state: extract('administrative_area_level_1', 'long_name'),
      stateCode: extract('administrative_area_level_1', 'short_name'),
      postalCode: extract('postal_code'),
      postalCode2: extract('postal_code_suffix'),
      countryCode: extract('country', 'short_name'),
    };
    return cityResponse;
  };

  const cityByPostalCode = (
    postalCode: string
  ): Promise<GoogleGeocodeCityResponse> => {
    return fetch(
      `${GOOGLE_GEOCODE_API}/json?address=${postalCode}&components=postal_code:${postalCode}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .then(extractGeocodeResult);
  };

  return {
    cityByPostalCode,
  };
};

export default useGoogleGeocoder;
