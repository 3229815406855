import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  BrandThemeProvider,
  DynamicAnimation,
  getTheme,
  resetViewportUnits,
} from '@slicepayments/web-components';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import Router from './Router';
import {
  AuthProvider,
  LoggedIn,
  ServicesProvider,
  FullscreenProvider,
  NotLoggedIn,
  WaitlistProvider,
} from './hooks';
import GlobalStyles from './GlobalStyles';
import Auth from './pages/Auth';
import 'animate.css';

const App = () => {
  const theme = getTheme();
  const queryClient = new QueryClient();

  const antdTheme = {
    token: {
      colorPrimary: theme.color.kiwi,
      fontFamily: `'General Sans'`,
    },
  };

  useEffect(() => {
    let debounceTimeout;
    window.addEventListener('resize', () => {
      window.clearTimeout(debounceTimeout);
      window.setTimeout(resetViewportUnits, 200);
    });

    resetViewportUnits();

    return () => {
      window.removeEventListener('resize', resetViewportUnits);
    };
  }, []);
  // loadHotJar();
  // checkForLocalStorageExpiredKeys();
  return (
    <QueryClientProvider client={queryClient}>
      <BrandThemeProvider theme={theme}>
        <AntdConfigProvider theme={antdTheme}>
          <GlobalStyles theme={theme} />
          <FullscreenProvider>
            <AuthProvider>
              <ServicesProvider>
                <LoggedIn>
                  <WaitlistProvider>
                    {/* <DynamicAnimation animationDurationSeconds={2} animationName="fadeIn"> */}
                    <Router />
                    {/* </DynamicAnimation> */}
                  </WaitlistProvider>
                </LoggedIn>
                <NotLoggedIn>
                  {/* <DynamicAnimation animationDurationSeconds={1} animationName="fadeIn"> */}
                  <Auth />
                  {/* </DynamicAnimation> */}
                </NotLoggedIn>
              </ServicesProvider>
            </AuthProvider>
          </FullscreenProvider>
        </AntdConfigProvider>
      </BrandThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
