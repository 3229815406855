import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import AddressAutocomplete, {
  extractAddressData,
} from '../../../components/AddressAutoComplete';
import { StyledForm } from '../shared/components';
import { FormProps } from 'antd/lib/form';
import { debounce } from '../shared/utils';
import { useGoogleGeocoder } from '../../../hooks';
import { fullToAbbreviatedStateMap } from '../../../lib/utils';
import { AddressStep } from '../pages/RentalAddressPage';

// Types
export interface RentalAddressFormProps extends FormProps {
  form: any;
  initialValues: any;
  onChange: any;
  step: AddressStep;
}
export type StateSelectOption = {
  state: string;
  value: string;
  label: string;
};

// Constants
export const minPostalCodeLength = 5;

// Component
const RentalAddressForm: React.FC<RentalAddressFormProps> = ({
  form,
  initialValues,
  onChange,
  step = AddressStep.SEARCH,
}) => {
  const { cityByPostalCode } = useGoogleGeocoder();
  const [stateOptions, setStateOptions] =
    useState<StateSelectOption[]>(stateSelectOptions);

  const setAddressValuesOnAutocomplete = (geocodeResult) => {
    Object.entries(extractGeocodeResult(geocodeResult)).forEach(
      ([field, value]) => {
        form.setFieldValue(field, value);
      }
    );
    onChange();
  };

  const setAddressValuesOnPostalCodeChange = ({
    city,
    stateCode,
    countryCode,
  }) => {
    const updatedValues = {
      postalCode2: undefined,
      city,
      stateCode,
      countryCode,
    };
    Object.entries(updatedValues).forEach(([field, value]) => {
      form.setFieldValue(field, value);
    });
    onChange();
  };

  const onPostalCodeChange = ({ postalCode }) => {
    if (postalCode && postalCode.length >= minPostalCodeLength) {
      const debounced = debounce(cityByPostalCode, 1000);
      debounced(postalCode).then(setAddressValuesOnPostalCodeChange);
    }
  };

  const handleSelectStateSearch = (value: string) => {
    const filteredOptions = stateSelectOptions.filter((option) => {
      const matches =
        option.value
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim()) ||
        option.state.toLowerCase().trim().includes(value.toLowerCase().trim());
      return matches;
    });
    setStateOptions(filteredOptions);
  };

  return (
    <StyledForm
      initialValues={initialValues}
      form={form}
      onValuesChange={onPostalCodeChange}
      layout="vertical"
    >
      {/* Address Search */}
      {step == AddressStep.SEARCH && (
        <AddressAutocomplete onChange={setAddressValuesOnAutocomplete} />
      )}
      {/* Address 1 */}
      <Form.Item className={`slice`} name="address1" hidden={true} hasFeedback>
        <Input placeholder="Enter address" />
      </Form.Item>
      {/* Address 2 */}
      <Form.Item
        className={`slice`}
        name="address2"
        label="Apt, suite, #"
        hidden={step !== AddressStep.EDIT}
        hasFeedback
      >
        <Input placeholder="Enter address line 2" />
      </Form.Item>
      {/* City */}
      <Form.Item
        className={`slice`}
        name="city"
        label="City"
        hidden
        rules={[{ required: true, message: 'City is required' }]}
        hasFeedback
      >
        <Input placeholder="Los Angeles" />
      </Form.Item>
      {/* State */}
      <Form.Item
        className={`slice`}
        name="stateCode"
        label="State"
        hidden
        rules={[{ required: true, message: 'State is required' }]}
        hasFeedback
      >
        <Select
          showSearch
          filterOption={false}
          onSearch={handleSelectStateSearch}
          placement="bottomRight"
          listHeight={150}
          options={stateOptions}
        />
      </Form.Item>
      {/* Postal Code */}
      <Form.Item
        className={`slice`}
        name="postalCode"
        label="Zipcode"
        hidden
        rules={[
          { required: true, message: 'Zip code is required' },
          {
            min: minPostalCodeLength,
            message: 'Postal code must be at least 5 characters',
          },
        ]}
        hasFeedback
      >
        <Input placeholder="90049" />
      </Form.Item>
      {/* Hidden */}
      <Form.Item
        hidden
        className="slice"
        name="postalCode2"
        label="postalCode2"
      >
        <Input placeholder="" />
      </Form.Item>
      {/* Hidden */}
      <Form.Item
        hidden
        className="slice"
        name="countryCode"
        label="countryCode"
        rules={[{ required: true }]}
      >
        <Input placeholder="" />
      </Form.Item>
    </StyledForm>
  );
};

export default RentalAddressForm;

// Helpers
const extractGeocodeResult = (geocodeResult) => {
  if (geocodeResult.length === 0) return {};
  const extract = extractAddressData(geocodeResult[0].address_components);
  return {
    address1: `${extract('street_number')} ${extract('route')}`,
    city: extract('locality'),
    state: extract('administrative_area_level_1', 'long_name'),
    stateCode: extract('administrative_area_level_1', 'short_name'),
    postalCode: extract('postal_code'),
    postalCode2: extract('postal_code_suffix'),
    countryCode: extract('country', 'short_name'),
  };
};

const stateSelectOptions: StateSelectOption[] = Object.entries(
  fullToAbbreviatedStateMap
).reduce((stateCodes: StateSelectOption[], [state, abbr]) => {
  return [...stateCodes, { state, value: abbr, label: abbr }];
}, []);
