import styled from '@emotion/styled';
import { DynamicAnimation } from '@slicepayments/web-components';
import React from 'react';
import { ReactComponent as SliceSvg } from './slice.svg';

const PageLoader: React.FC = () => {
  return (
    <PageSpinnerWrapper>
      <DynamicAnimation
        animationName={'fadeOut'}
        animationDelaySeconds={0}
        animationDurationSeconds={1}
      >
        <SliceSvg height={100} width={100} />
      </DynamicAnimation>
    </PageSpinnerWrapper>
  );
};

const PageSpinnerWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 100vh;
  width: 100%;
  color: ${({ theme }) => theme.color.kiwi};
  background: ${({ theme }) => theme.color.coconut};

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default PageLoader;
