import React, { useEffect, useState } from 'react';
import {
  ResponsiveLayout,
  EmojiList,
  EmojiListItemProps,
  Footer,
  Header,
} from '@slicepayments/web-components';
import { useMediaQuery, useMultiPageForm, usePlaidAuth } from '../../../hooks';
import { useTheme } from '@emotion/react';
import { onboardingFormPageTheme } from '../shared/constants';
import { ContentWrapper, FormPageWrapper } from '../shared/components';
import { useOnboarding } from '../../../hooks/useOnboarding';

const pageTitleRow1 = 'Link your bank';
const stepsWithEmojis: EmojiListItemProps[] = [
  {
    emoji: '🖇',
    label: 'Link the checking or savings account you pay rent from',
    color: '#BEEDFF',
    className: '',
  },
  {
    emoji: '👌',
    label: 'It’s easy to add or remove accounts after onboarding',
    color: '#FFF5A6',
    className: '',
  },
];
// TODO: Ensure we update plaid account in the future if the user has already linked their bank account info
const LinkBankPage = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const { currentPage, totalPages, setPreviousPage, setNextPage } =
    useMultiPageForm();
  const { details } = useOnboarding();
  const [showPlaid, setShowPlaid] = useState<boolean>(false);
  const { isAccountLinked, ready, open, generatePlaidConfig } = usePlaidAuth();

  const loadPlaid = async () => {
    await generatePlaidConfig(details.mobileNumber);
    setShowPlaid(true);
  };

  useEffect(() => {
    if (ready && showPlaid) {
      open();
    }
  }, [showPlaid, ready, open]);

  useEffect(() => {
    if (isAccountLinked) {
      setNextPage();
    }
  }, [isAccountLinked, setNextPage]);

  return (
    <FormPageWrapper ref={ref}>
      <ResponsiveLayout
        pageTheme={onboardingFormPageTheme}
        header={<Header titleRow1={pageTitleRow1} animate={true} />}
        content={
          <ContentWrapper>
            <EmojiList items={stepsWithEmojis}></EmojiList>
          </ContentWrapper>
        }
        footer={
          <Footer
            buttons={[
              {
                text: 'Back',
                fill: 'unfilled',
                variant: 'black',
                textDecoration: 'underline',
                disabled: currentPage === 0,
                onClick: setPreviousPage,
              },
              {
                text: 'Next',
                fill: 'filled',
                variant: 'primary',
                disabled: false,
                onClick: loadPlaid,
              },
            ]}
          />
        }
      />
    </FormPageWrapper>
  );
});

export default LinkBankPage;
