import React, { useRef, useState } from 'react';
import {
  DynamicAnimation,
  Footer,
  FooterButtonProps,
  Header,
  NavBar,
  ResponsiveLayout,
} from '@slicepayments/web-components';
import { Form, FormInstance, Input, InputRef } from 'antd';
import {
  useFormPage,
  useMultiPageForm,
  useService,
  useGoogleAddressValidator,
  useLayout,
  useMediaQuery,
} from '../../../hooks';
import {
  FormPageWrapper,
  ContentWrapper,
  StyledForm,
} from '../shared/components';
import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import { useOnboarding } from '../../../hooks/useOnboarding';
import { OnboardingUserDetails } from '../../../types';
import { OnboardingSubmitAddress } from '../../../lib/services/onboarding';
import { onboardingPageAnimations } from '../shared/utils';
import {
  LayoutPropsByDevice,
  pageLayoutPropsByKind,
} from '../../../hooks/useLayout';
import RentalAddressForm from '../forms/RentalAddressForm';
import SliceBuildCredit from '../assets/slice-build-credit.svg';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { OnboardingPageProps } from '../shared/types';

type RentalAddressFormValues = {
  address1?: string;
  address2?: string;
  city?: string;
  stateCode?: string;
  postalCode?: string;
  postalCode2?: string;
  countryCode?: string;
};

export enum AddressStep {
  ADD = 'ADD',
  EDIT = 'EDIT',
  SEARCH = 'SEARCH',
}

// Constants
const pageLayoutProps: LayoutPropsByDevice = {
  desktop: {
    ...pageLayoutPropsByKind.form.desktop,
    pageTheme: {
      bgColor: '#98C5F6',
      image: SliceBuildCredit,
    },
  },
  mobile: {
    ...pageLayoutPropsByKind.form.mobile,
  },
};
const { animationName, duration, delayStep } = onboardingPageAnimations;

// Page
const RentalAddressPage = React.forwardRef<HTMLDivElement, OnboardingPageProps>(
  ({ pageIndex }, ref) => {
    const { pageTheme, titleFontSize, descriptionFontSize, textAlign } =
      useLayout(pageLayoutProps);

    const {
      currentPage,
      isTransitioning,
      setPreviousPage,
      setNextPage,
      onPageSubmit,
    } = useMultiPageForm();

    const { details, setDetails } = useOnboarding();

    const {
      form,
      initialValues,
      showCancelModal,
      isFormValid,
      isLoading,
      setShowCancelModal,
      onChange,
      hasChanges,
    } = useFormPage<OnboardingUserDetails, RentalAddressFormValues>({
      data: details,
      toFormValues,
    });

    const onboardingService = useService<'onboarding'>('onboarding');
    const { validateAddress } = useGoogleAddressValidator();
    const inputRef = useRef<InputRef | null>(null);
    const { mediaQuery } = useTheme();
    const minWidthMedium = useMediaQuery(mediaQuery.medium);
    const [step, setStep] = useState<AddressStep>(AddressStep.ADD);

    const submitForm = async () => {
      // Go to next page if there's no changes
      if (!hasChanges()) {
        setNextPage();
        return;
      }

      const results = await validateAddress(form.getFieldsValue());

      if (results?.result?.verdict?.addressComplete) {
        await form.validateFields();

        const requestBody = toRequestBody(form, details.userId);
        const request = onboardingService.submitAddress(requestBody);

        await onPageSubmit(request)
          .then((res) => {
            setDetails(res);
            setNextPage();
          })
          .catch((e) => {
            // TODO: handle null
            return null;
          });
      } else {
        // show invalid address
      }
    };

    const handleInputClick = () => {
      // Create dynamic keyframes based on the position
      if (inputRef.current && !minWidthMedium) {
        const position = inputRef.current?.input
          ?.closest('.slice')
          ?.getBoundingClientRect();
        const navBarHeight = 62;
        const translateY = (position?.top || 0) - navBarHeight;

        const keyframes = `
        @keyframes custom {
          from {
            transform: translateY(${translateY}px);
          }
          to {
            transform: translateY(0px);
          }
        }
      `;

        // Add the dynamic keyframes to the page
        const styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        styleSheet.innerText = keyframes;
        document.head.appendChild(styleSheet);
      }
      setStep(AddressStep.SEARCH);
    };

    const onAddressChange = () => {
      onChange();
      setStep(AddressStep.EDIT);
    };

    const headerByStep = {
      [AddressStep.ADD]: () => (
        <Header
          titleRow1={'Residential Address'}
          titleFontSize={titleFontSize}
          textAlign={textAlign}
          animationName={animationName}
          animationDurationSeconds={duration}
          animationDelayStepSeconds={delayStep}
          animationInitialDelaySeconds={delayStep}
          animate={true}
        />
      ),
      [AddressStep.EDIT]: () => (
        <Header
          titleRow1={form.getFieldValue('address1')}
          titleRow2={`${form.getFieldValue('city')}, ${form.getFieldValue(
            'stateCode'
          )} ${form.getFieldValue('postalCode')}`}
          titleFontSize={titleFontSize}
          textAlign={textAlign}
          animationName={animationName}
          animationDurationSeconds={duration}
          animationDelayStepSeconds={delayStep}
          animationInitialDelaySeconds={delayStep}
          animate={true}
        />
      ),
      [AddressStep.SEARCH]: () =>
        minWidthMedium ? (
          <Header
            titleRow1={''}
            titleFontSize={titleFontSize}
            description={''}
            descriptionFontSize={descriptionFontSize}
            textAlign={textAlign}
            animationName={animationName}
            animationDurationSeconds={duration}
            animationDelayStepSeconds={delayStep}
            animationInitialDelaySeconds={delayStep}
            animate={true}
          />
        ) : undefined,
    };

    const goBack = () => {
      if (step === AddressStep.SEARCH) {
        setStep(AddressStep.ADD);
      } else if (step === AddressStep.EDIT) {
        setStep(AddressStep.ADD);
      } else {
        setPreviousPage();
      }
    };

    const defaultButtons: FooterButtonProps[] = [
      {
        text: 'Back',
        fill: 'filled',
        variant: 'white',
        border: 'border',
        size: 'small',
        disabled: false,
        onClick: goBack,
      },
      {
        text: 'Next',
        fill: 'filled',
        variant: 'primary',
        size: 'small',
        loading: pageIndex > currentPage || isTransitioning,
        disabled: !isFormValid,
        onClick: submitForm,
      },
    ];

    const footerByStep = {
      [AddressStep.ADD]: () => (
        <Footer
          buttons={[
            { ...defaultButtons[0] },
            {
              ...defaultButtons[1],
              onClick: () => setStep(AddressStep.EDIT),
            },
          ]}
        />
      ),
      [AddressStep.EDIT]: (form: FormInstance<any>, userId: string) => (
        <Footer
          buttons={[
            { ...defaultButtons[0] },
            {
              ...defaultButtons[1],
              onClick: submitForm,
            },
          ]}
        />
      ),
      [AddressStep.SEARCH]: () => (
        <Footer
          buttons={[
            { ...defaultButtons[0] },
            {
              ...defaultButtons[1],
              onClick: () => setStep(AddressStep.EDIT),
            },
          ]}
        />
      ),
    };

    const addContent = (
      <ContentWrapper>
        <DynamicAnimation
          animationName={animationName}
          animationDurationSeconds={duration}
          animationDelaySeconds={delayStep}
        >
          <StyledForm
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <Form.Item
              className={`slice`}
              name="address1"
              label="Street address"
              hasFeedback
              rules={[{ required: true }]}
            >
              <Input
                ref={inputRef}
                onClick={handleInputClick}
                placeholder={'Enter your address'}
              />
            </Form.Item>
          </StyledForm>
        </DynamicAnimation>
        <DisclaimerSection>
          <DynamicAnimation
            animationName={animationName}
            animationDurationSeconds={duration}
            animationDelaySeconds={delayStep * 2}
          >
            Slice is required by law to collect this information. We will never
            share this information with marketers.
          </DynamicAnimation>
        </DisclaimerSection>
      </ContentWrapper>
    );

    const searchContent = (
      <ContentWrapper>
        {/* <SearchWrapper> */}
        <DynamicAnimation
          animationName={'custom'}
          animationDurationSeconds={0.5}
          animationDelaySeconds={0}
        >
          <RentalAddressForm
            form={form}
            initialValues={{ countryCode: 'US' }}
            onChange={onAddressChange}
            step={AddressStep.SEARCH}
          />
        </DynamicAnimation>
        {/* </SearchWrapper> */}
      </ContentWrapper>
    );

    const editContent = (
      <ContentWrapper>
        <DynamicAnimation
          animationName={animationName}
          animationDurationSeconds={duration}
          animationDelaySeconds={delayStep}
        >
          <RentalAddressForm
            form={form}
            initialValues={{ countryCode: 'US' }}
            onChange={onAddressChange}
            step={AddressStep.EDIT}
          />
        </DynamicAnimation>
      </ContentWrapper>
    );

    const nav = <NavBar handleClick={goBack} kind="navigation" />;

    return (
      <FormPageWrapper ref={ref}>
        <ConfirmCancelModal
          showModal={showCancelModal}
          onCancel={() => setShowCancelModal(false)}
          onOk={() => {
            setShowCancelModal(false);
            setPreviousPage();
          }}
        />
        {step === AddressStep.SEARCH && (
          <ResponsiveLayout
            pageTheme={pageTheme}
            nav={nav}
            header={headerByStep[AddressStep.SEARCH]()}
            content={searchContent}
            footer={footerByStep[AddressStep.SEARCH]()}
          />
        )}
        {step === AddressStep.EDIT && (
          <ResponsiveLayout
            pageTheme={pageTheme}
            nav={nav}
            header={headerByStep[AddressStep.EDIT]()}
            content={editContent}
            footer={footerByStep[AddressStep.EDIT](form, details.userId)}
          />
        )}
        {step === AddressStep.ADD && (
          <ResponsiveLayout
            pageTheme={pageTheme}
            nav={nav}
            header={headerByStep[AddressStep.ADD]()}
            content={addContent}
            footer={footerByStep[AddressStep.ADD]()}
          />
        )}
        {isLoading && <h1>TRUE!!!!</h1>}
      </FormPageWrapper>
    );
  }
);

export default RentalAddressPage;

// Helpers
const toFormValues = (
  details: OnboardingUserDetails
): RentalAddressFormValues => {
  return {
    address1: details.address?.address1,
    address2: details.address?.address2,
    city: details.address?.city,
    stateCode: details.address?.stateCode,
    postalCode: details.address?.postalCode,
    postalCode2: details.address?.postalCode2,
    countryCode: details.address?.countryCode,
  };
};

const toRequestBody = (
  form: FormInstance<any>,
  userId: string
): OnboardingSubmitAddress => {
  return {
    userId: userId,
    address: {
      address1: form.getFieldValue('address1'),
      address2: form.getFieldValue('address2'),
      city: form.getFieldValue('city'),
      stateCode: form.getFieldValue('stateCode'),
      postalCode: form.getFieldValue('postalCode'),
      postalCode2: form.getFieldValue('postalCode2'),
      countryCode: form.getFieldValue('countryCode'),
    },
  };
};

const DisclaimerSection = styled.div`
  align-self: flex-end;
  margin-top: auto;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorMap.gray.gray2};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    text-align: center;
    font-size: 1rem;
  }
`;
