import React from 'react';
import styled from '@emotion/styled';

type AppPageComponentProps = {
  children?: React.ReactNode;
};

const AppPageComponent: React.FC<AppPageComponentProps> = ({ children }) => {
  return <AppPage>{children}</AppPage>;
};

export const AppPage = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`;

export default AppPageComponent;
